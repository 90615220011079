import { SelectorsFactory } from '../factories/selectors.factory';

export class PlacesSelectors extends SelectorsFactory {
  // eslint-disable-next-line no-underscore-dangle
  _byId(id) {
    const { sliceName } = this;
    return (state) => state[sliceName].list.find((e) => e.placeId === id);
  }
}

export const placesSelectors = new PlacesSelectors('places');
