/* eslint-disable no-underscore-dangle */
import { SelectorsFactory } from '../factories/selectors.factory';

export class BookingsSelectors extends SelectorsFactory {
  // eslint-disable-next-line no-underscore-dangle
  _byId(id) {
    const { sliceName } = this;
    return (state) => state[sliceName].list.find((e) => e.bookingId === id);
  }

  _lockedBookingList(state) {
    return state[this.sliceName].lockedBookings;
  }

  lockedBookingList() {
    return this._lockedBookingList.bind(this);
  }
}

export const bookingsSelectors = new BookingsSelectors('bookings');
