import { APP_LANGS } from '../../../constants';

export const UiComponetKeys = {
  MENU_DRAWER: 'MENU_DRAWER',
  TOP_SEARCHBAR: 'TOP_SEARCHBAR',
  LOADING_DIALOG: 'LOADING_DIALOG',
  ERROR_DIALOG: 'ERROR_DIALOG',
  RULES_DIALOG: 'RULES_DIALOG',
  BOOKING_DIALOG: 'BOOKING_DIALOG',
  SESSION_EXPIRED_DIALOG: 'SESSION_EXPIRED_DIALOG'
};

export const uiInitialState = {
  [UiComponetKeys.MENU_DRAWER]: {
    opened: false,
    data: null,
  },
  [UiComponetKeys.TOP_SEARCHBAR]: {
    opened: true,
    data: null,
  },
  [UiComponetKeys.LOADING_DIALOG]: {
    opened: false,
    data: null,
  },
  [UiComponetKeys.ERROR_DIALOG]: {
    opened: false,
    data: { msg: 'Errore sconosciuto' },
  },
  [UiComponetKeys.RULES_DIALOG]: {
    opened: false,
    data: { msg: null },
  },
  [UiComponetKeys.BOOKING_DIALOG]: {
    opened: false,
    data: {},
  },
  [UiComponetKeys.SESSION_EXPIRED_DIALOG]: {
    opened: false,
    data: { msg: 'Sessione scaduta' },
  },
  currentLang: APP_LANGS.ITA.key,
  loadingTasks: [],
};
