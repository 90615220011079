import { InitialStateFactory } from '../factories/initialState.factory';
import { ReducerFactory } from '../factories/reducers.factory';

const bookingsInitialState = {
  ...(InitialStateFactory()),
  lockedBookings: []
};

const bookingsCustomActions = {
  'BOOKINGS/SET_BY_ID': (state, { payload, id }) => (
    {
      ...state,
      list: state.list.map((e) => (e.bookingId === id ? ({ ...e, ...payload }) : e)),
      current: state.current?.pitchId === id
        ? ({ ...state.current, ...payload })
        : state.current,
    }),
  'BOOKINGS/SET_LOCKED_BOOKINGS': (state, lockedBookings) => (
    {
      ...state,
      lockedBookings
    }),
};

export const bookingsReducer = ReducerFactory('bookings', bookingsInitialState, bookingsCustomActions);
