import { InitialStateFactory } from '../factories/initialState.factory';
import { ReducerFactory } from '../factories/reducers.factory';

const timeframesActions = {
  'TIMEFRAMES/SET_BY_ID': (state, { payload, id }) => (
    {
      ...state,
      list: state.list.map((e) => (e.pitchTimeframeId === id ? ({ ...e, ...payload }) : e)),
      current: state.current?.pitchTimeframeId === id
        ? ({ ...state.current, ...payload })
        : state.current,
    }),
};

export const timeframesReducer = ReducerFactory('timeframes', InitialStateFactory(), timeframesActions);
