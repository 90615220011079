/**
 *
 * @param {File} file
 * @returns {Promise<string>}
 */
export const readFileAsUrl = (file) => new Promise((resolve, reject) => {
  const reader = new FileReader();
  reader.readAsDataURL(file);
  reader.onload = () => {
    resolve(reader.result);
  };
  reader.onerror = (error) => reject(error);
});
