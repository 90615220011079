import { useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import {
  Button, Card, CardActions, CardContent, CardMedia, Divider, Grid, TextField, Typography,
} from '@mui/material';
import PlusIcon from '@mui/icons-material/AddToPhotos';

import { getI18NText } from './utils/utils';
import { selectCurrentLanguage } from './slices/ui/uiSelectors';
import { pitchesSelectors } from './slices/pitches/pitches.selectors';
import { PitchesActions } from './slices/pitches/pitches.actions';
import { WEB_BASE_URL } from '../constants';
import { selectLoginData } from './slices/app/appSelectors';
import { UiComponetKeys } from './slices/ui/uiInitialState';
import {
  addLoadingTask, setDialogData, setDialogOpened, solveLoadingTask
} from './slices/ui/uiActions';

const DEFAULT_IMAGE_URL = 'https://carpfishing.h501.io/img/no-image.png';
function Pitch({ pitch, placeId }) {
  const navigate = useNavigate();

  const [pitchImageUrl, setPitchImageUrl] = useState(pitch.pitchImgUrl);
  const curLang = useSelector(selectCurrentLanguage);

  const onGoToPitch = (pitchId) => {
    navigate(`/places/${placeId}/pitches/${pitchId}`);
  };

  return (
    <Grid item xs={3}>
      <Card>
        <CardMedia
          component="img"
          height="200"
          image={pitchImageUrl}
          alt={pitch.pitchName}
          onError={() => setPitchImageUrl(DEFAULT_IMAGE_URL)}
        />
        <CardContent>
          <Typography gutterBottom variant="h5" component="div">
            {pitch.pitchName}
          </Typography>
        </CardContent>
        <CardActions sx={{
          display: 'flex',
          justifyContent: 'flex-end'
        }}
        >
          <Button
            onClick={() => onGoToPitch(pitch.pitchId)}
            style={{ marginRight: 10 }}
            size="small"
            variant="contained"
          >
            {getI18NText('actions.update', curLang)}
          </Button>
        </CardActions>
      </Card>
    </Grid>
  );
}

Pitch.propTypes = {
  pitch: PropTypes.shape({
    pitchImgUrl: PropTypes.string.isRequired,
    pitchId: PropTypes.number.isRequired,
    pitchName: PropTypes.string.isRequired,
  }).isRequired,
  placeId: PropTypes.number.isRequired,
};

export default function PitchesComponent() {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { placeId } = useParams();
  const curLang = useSelector(selectCurrentLanguage);
  const pitches = useSelector(pitchesSelectors.list());
  const [pitchRules, setPitchRules] = useState('');
  const loginData = useSelector(selectLoginData);

  useEffect(() => {
    dispatch(PitchesActions.getList(placeId));
    const getRules = async () => {
      const response = await fetch(`${WEB_BASE_URL}/rules`, {
        method: 'get',
        headers: {
          'content-type': 'application/json',
        }
      });
      const { rules } = await response.json();
      setPitchRules(rules);
    };
    getRules();
  }, []);

  const onUpdateRules = (e) => {
    e.preventDefault();
    dispatch(addLoadingTask());
    const update = async () => {
      try {
        await fetch(`${WEB_BASE_URL}/rules`, {
          method: 'post',
          headers: {
            authorization: `Bearer ${loginData.jwt}`,
            'content-type': 'application/json',
          },
          body: JSON.stringify({ rules: pitchRules })
        });
        dispatch(setDialogData(
          UiComponetKeys.ERROR_DIALOG,
          { msg: 'Aggiornamento effettuato con successo.' }
        ));
      } catch (err) {
        console.error(err);
        dispatch(setDialogData(
          UiComponetKeys.ERROR_DIALOG,
          { msg: 'Impossibile aggiornare le regole di pesca.' }
        ));
      } finally {
        dispatch(setDialogOpened(UiComponetKeys.ERROR_DIALOG, true));
        dispatch(solveLoadingTask());
      }
    };
    update();
  };

  const pitchesElements = useMemo(() => pitches.map((pitch) => <Pitch pitch={pitch} placeId={parseInt(placeId, 10)} key={`pitch-${pitch.pitchId}`} />), [pitches, curLang]);

  return (
    <Grid container spacing={1}>

      <Grid
        item
        xs={3}
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          marginTop: '5rem',
        }}
      >
        <PlusIcon
          onClick={() => navigate(`/places/${placeId}/pitches/add`)}
          sx={{
            height: '5rem',
            width: '5rem',
            cursor: 'pointer',
          }}
        />
        <Typography gutterBottom variant="h5" component="div">
          Aggiungi nuova piazzola
        </Typography>
      </Grid>

      {pitchesElements}

      <Grid item xs={12}>
        <Divider variant="fullWidth" style={{ marginTop: '1rem', marginBottom: '1rem' }} />
        <Typography>Regolamento</Typography>
        <TextField
          id="rules-textarea"
          label="Regolamento"
          placeholder="Inserire regolamento"
          multiline
          style={{ minWidth: '50%', marginTop: '.4rem' }}
          value={pitchRules}
          onChange={(evt) => setPitchRules(evt.target.value)}
        />
        <Grid xs={12} style={{ marginTop: '1rem' }}>
          <Button variant="contained" onClick={onUpdateRules}> Aggiorna </Button>
        </Grid>
      </Grid>
    </Grid>
  );
}
