import { AppActionTypes } from './appActionTypes';
import { appInitialState } from './appInitialState';

// eslint-disable-next-line default-param-last
export const appReducers = (state = appInitialState, action) => {
  switch (action.type) {
    case AppActionTypes.SET_ENUM_ORDER_STATUSES:
      return {
        ...state,
        enums: {
          orderStatuses: action.payload,
        },
      };

    case AppActionTypes.SET_LOGIN_STATUS:
      return {
        ...state,
        isLogged: action.payload,
      };

    case AppActionTypes.SET_LOGIN_DATA:
      return {
        ...state,
        login: {
          ...state.login,
          ...action.payload.userData,
        },
      };

    case AppActionTypes.SET_IS_APP_SETUP:
      return {
        ...state,
        isAppSetup: action.payload,
      };

    case AppActionTypes.SET_APP_STATE:
      return {
        ...state,
        ...action.payload,
      };

    default:
      return state;
  }
};
