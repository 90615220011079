const UiActionTypes = {
  GET_DIALOG: 'UI/GET_DIALOG',
  ADD_DIALOG: 'UI/ADD_DIALOG',
  IS_DIALOG_OPENED: 'UI/IS_DIALOG_OPENED',
  SET_DIALOG_OPENED: 'UI/SET_DIALOG_OPENED',
  GET_DIALOG_DATA: 'UI/GET_DIALOG_DATA',
  SET_DIALOG_DATA: 'UI/SET_DIALOG_DATA',
  RESET_DIALOG: 'UI/RESET_DIALOG',
  ADD_LOADING_TASK: 'UI/ADD_LOADING_TASK',
  ADD_LOADING_TASK_SLICE: 'UI/ADD_LOADING_TASK_SLICE',
  SOLVE_LOADING_TASK: 'UI/SOLVE_LOADING_TASK',
  SOLVE_LOADING_TASK_SLICE: 'UI/SOLVE_LOADING_TASK_SLICE',
  SET_APP_LANG: 'UI/SET_APP_LANG',
  SET_APP_LANG_SLICE: 'UI/SET_APP_LANG_SLICE',
};

export default UiActionTypes;
