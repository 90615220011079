import { DEFAULT_ACTION_TYPES } from './actionTypes.factory';

export class ActionsFactory {
  baseSliceName;

  constructor(baseSliceName, actionsTypes = DEFAULT_ACTION_TYPES) {
    if (!baseSliceName) {
      throw new Error('No base slice name found.');
    }
    this.baseSliceName = baseSliceName;
    this.actionsTypes = actionsTypes;
  }

  getList() {
    return {
      type: this.actionsTypes.GET_LIST,
    };
  }

  setList(list) {
    return {
      type: this.actionsTypes.SET_LIST,
      payload: list,
    };
  }

  getById(id) {
    return {
      type: this.actionsTypes.GET_BY_ID,
      payload: id,
    };
  }

  setById({ payload, id }) {
    return {
      type: this.actionsTypes.SET_BY_ID,
      payload: { payload, id },
    };
  }

  add(payload) {
    return {
      type: this.actionsTypes.ADD,
      payload,
    };
  }

  create(payload) {
    return {
      type: this.actionsTypes.CREATE,
      payload,
    };
  }

  update(payload, id) {
    return {
      type: this.actionsTypes.UPDATE,
      payload: { payload, id },
    };
  }
}
