import { InitialStateFactory } from '../factories/initialState.factory';
import { ReducerFactory } from '../factories/reducers.factory';

const pitchesActions = {
  'PITCHES/SET_BY_ID': (state, { payload, id }) => (
    {
      ...state,
      list: state.list.map((e) => (e.pitchId === id ? ({ ...e, ...payload }) : e)),
      current: state.current?.pitchId === id
        ? ({ ...state.current, ...payload })
        : state.current,
    }),
};

export const pitchesReducer = ReducerFactory('pitches', InitialStateFactory(), pitchesActions);
