import { InitialStateFactory } from '../factories/initialState.factory';
import { ReducerFactory } from '../factories/reducers.factory';

const placesActions = {
  'PLACES/SET_BY_ID': (state, { payload, placeId }) => (
    {
      ...state,
      list: state.list.map((e) => (e.placeId === placeId ? ({ ...e, ...payload }) : e)),
      current: state.current?.placeId === placeId
        ? ({ ...state.current, ...payload })
        : state.current,
    }),
};

export const placesReducer = ReducerFactory('places', InitialStateFactory(), placesActions);
