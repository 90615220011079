import { i18n } from '../../../constants';

// eslint-disable-next-line func-names
export const selectDialog = (key) => function (state) {
  // eslint-disable-next-line react/destructuring-assignment
  return state.ui[key] ? state.ui[key] : null;
};

export const selectIsDialogOpened = (key) => (
  (state) => (state.ui[key] ? state.ui[key].opened : false));

// eslint-disable-next-line func-names
export const selectDialogData = (key) => function (state) {
  // eslint-disable-next-line react/destructuring-assignment
  return state.ui[key] ? state.ui[key].data : null;
};

export const selectLoadingTasks = (state) => state.ui.loadingTasks;

export const selectCurrentTranslation = (state) => i18n[state.ui.currentLang] || {};

export const selectCurrentLanguage = (state) => state.ui.currentLang;
