import {
  select, call, put, takeEvery,
} from 'redux-saga/effects';
import { ApiFactory } from './api.factory';
import {
  addLoadingTask, setDialogData, setDialogOpened, solveLoadingTask,
} from '../ui/uiActions';
import { UiComponetKeys } from '../ui/uiInitialState';
import { checkToken } from '../app/appSagas';
import { getI18NText } from '../../utils/utils';
import { selectCurrentLanguage } from '../ui/uiSelectors';

export class SagasFactory {
  baseSliceName;

  resourceName;

  /**
     * @param {ApiFactory}
     */
  apiClient;

  /**
     * @param {ActionsFactory}
     */
  actions;

  actionTypes;

  /**
     *
     * @param {*} baseSliceName
     * @param {*} resourceName
     * @param {ApiFactory} ApiClientClass
     * @param {ActionsFactory} actions
     */
  // eslint-disable-next-line default-param-last
  constructor(baseSliceName, resourceName, ApiClientClass = ApiFactory, actions, actionTypes) {
    if (!baseSliceName) {
      throw new Error('No base slice name found.');
    }
    this.baseSliceName = baseSliceName;
    this.resourceName = resourceName;
    this.apiClient = new ApiClientClass(resourceName);
    this.actions = actions;
    this.actionTypes = actionTypes;
  }

  // eslint-disable-next-line class-methods-use-this
  * checkLogin() {
    const loginData = yield checkToken();
    if (!loginData) {
      const curLang = yield select(selectCurrentLanguage);
      throw new Error(getI18NText('errors.login_expired', curLang));
    }
    if (loginData.jwt === null) {
      throw new Error('Session expired');
    }
    this.apiClient.setJwt(loginData.jwt);
  }

  * list() {
    try {
      yield put(addLoadingTask());
      yield this.checkLogin();

      const list = yield call(this.apiClient.list.bind(this.apiClient));
      yield put(this.actions.setList(list));
    } catch (e) {
      console.error(e);
      yield put(setDialogData(UiComponetKeys.ERROR_DIALOG, { msg: `Impossibile recuperare la lista di ${this.resourceName} (${e.message})` }));
      yield put(setDialogOpened(UiComponetKeys.ERROR_DIALOG, true));
    } finally {
      yield put(solveLoadingTask());
    }
  }

  * byId({ payload: id }) {
    try {
      yield put(addLoadingTask());
      yield this.checkLogin();

      const resource = yield call(this.apiClient.byId.bind(this.apiClient), id);
      yield put(this.actions.setById({ id, payload: resource }));
    } catch (e) {
      console.error(e);
      yield put(setDialogData(UiComponetKeys.ERROR_DIALOG, { msg: `Impossibile recuperare la risorsa (${e.message})` }));
      yield put(setDialogOpened(UiComponetKeys.ERROR_DIALOG, true));
    } finally {
      yield put(solveLoadingTask());
    }
  }

  * create({ payload }) {
    try {
      yield put(addLoadingTask());
      yield this.checkLogin();

      const resource = yield call(this.apiClient.createOrUpdate.bind(this.apiClient), payload);
      yield put(this.actions.add({ ...resource, added: true }));
      yield put(setDialogData(UiComponetKeys.ERROR_DIALOG, { msg: 'Operazione eseguita con successo' }));
      yield put(setDialogOpened(UiComponetKeys.ERROR_DIALOG, true));
    } catch (e) {
      console.error(e);
      yield put(setDialogData(UiComponetKeys.ERROR_DIALOG, { msg: `Impossibile salvare la risorsa (${e.message})` }));
      yield put(setDialogOpened(UiComponetKeys.ERROR_DIALOG, true));
    } finally {
      yield put(solveLoadingTask());
    }
  }

  * update({ payload: { id, payload } }) {
    try {
      yield put(addLoadingTask());
      yield this.checkLogin();

      const resource = yield call(this.apiClient.createOrUpdate.bind(this.apiClient), payload, id);
      yield put(this.actions.setById({ id, payload: { ...resource, updated: true } }));
      yield put(setDialogData(UiComponetKeys.ERROR_DIALOG, { msg: 'Operazione eseguita con successo' }));
      yield put(setDialogOpened(UiComponetKeys.ERROR_DIALOG, true));
    } catch (e) {
      console.error(e);
      yield put(setDialogData(UiComponetKeys.ERROR_DIALOG, { msg: `Impossibile salvare la risorsa (${e.message})` }));
      yield put(setDialogOpened(UiComponetKeys.ERROR_DIALOG, true));
    } finally {
      yield put(solveLoadingTask());
    }
  }

  * watchList() {
    yield takeEvery(this.actionTypes.GET_LIST, this.list.bind(this));
  }

  * watchById() {
    yield takeEvery(this.actionTypes.GET_BY_ID, this.byId.bind(this));
  }

  * watchCreate() {
    yield takeEvery(this.actionTypes.CREATE, this.create.bind(this));
  }

  * watchUpdate() {
    yield takeEvery(this.actionTypes.UPDATE, this.update.bind(this));
  }

  // eslint-disable-next-line class-methods-use-this
  sliceSagas() {
    /**
     * return [
      this.myCustomSaga.bind(this),
      this.otherCustomSaga.bind(this),
    ];
     */
    return [];
  }

  getSagas() {
    return [
      this.watchList.bind(this),
      this.watchById.bind(this),
      this.watchCreate.bind(this),
      this.watchUpdate.bind(this),
      ...this.sliceSagas(),
    ];
  }
}
