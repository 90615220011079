import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import {
  Button, Card, CardActions, CardContent, CardHeader, Modal, Typography,
} from '@mui/material';
import { UiComponetKeys } from '../slices/ui/uiInitialState';
import { selectCurrentLanguage, selectDialogData, selectIsDialogOpened } from '../slices/ui/uiSelectors';
import { setDialogOpened } from '../slices/ui/uiActions';
import { getI18NText } from '../utils/utils';

export const ACTION_DIALOG_EVENTS = {
  CLOSE_EVENT: 'CLOSE_EVENT',
  ACCEPT_EVENT: 'ACCEPT_EVENT',
  CANCEL_EVENT: 'CANCEL_EVENT',
};

const modalStyle = {
  display: 'flex',
  justifyContent: 'center'
};

const cardStyle = {
  alignSelf: 'center',
  width: '50%'
};
export function ActionDialogComponent({ dialogKey, callback }) {
  const dispatch = useDispatch();

  const isOpened = useSelector(selectIsDialogOpened(dialogKey));
  const dialogData = useSelector(selectDialogData(dialogKey));
  const curLang = useSelector(selectCurrentLanguage);

  const onClose = () => {
    dispatch(setDialogOpened(dialogKey, false));
    if (callback) {
      callback(ACTION_DIALOG_EVENTS.CLOSE_EVENT);
    }
  };

  const onAccept = () => {
    dispatch(setDialogOpened(dialogKey, false));
    if (callback) {
      callback(ACTION_DIALOG_EVENTS.ACCEPT_EVENT);
    }
  };

  const onCancel = () => {
    dispatch(setDialogOpened(dialogKey, false));
    if (callback) {
      callback(ACTION_DIALOG_EVENTS.CANCEL_EVENT);
    }
  };

  return (
    <Modal sx={modalStyle} open={isOpened}>
      <Card sx={cardStyle}>
        <CardHeader title={dialogData?.title || getI18NText('warnings.general', curLang)} />
        <CardContent style={{ alignSelf: 'center' }}>
          <Typography>
            {dialogData?.msg}
          </Typography>
        </CardContent>
        <CardActions style={{
          display: 'flex',
          justifyContent: 'flex-end'
        }}
        >
          <Button
            size="small"
            style={{ display: dialogData?.cancelButton ? 'flex' : 'none', marginRight: 5 }}
            onClick={onCancel}
          >
            {dialogData?.cancelButtonText || getI18NText('actions.cancel', curLang)}
          </Button>
          <Button
            size="small"
            style={{ display: dialogData?.acceptButton ? 'flex' : 'none', marginRight: 5 }}
            onClick={onAccept}
          >
            {dialogData?.acceptButtonText || getI18NText('actions.ok', curLang)}
          </Button>
          <Button
            size="small"
            status="info"
            style={{ marginRight: 5 }}
            onClick={onClose}
          >
            {dialogData?.closeButtonText || getI18NText('actions.close', curLang)}
          </Button>
        </CardActions>
      </Card>
    </Modal>
  );
}

ActionDialogComponent.propTypes = {
  dialogKey: PropTypes.string,
  callback: PropTypes.func,
};

ActionDialogComponent.defaultProps = {
  dialogKey: UiComponetKeys.ERROR_DIALOG,
  callback: () => { }
};
