import {
  takeEvery, select, put, call,
} from 'redux-saga/effects';
import { AppActionTypes } from './appActionTypes';
import { selectLoginData } from './appSelectors';
import {
  addLoadingTask, setDialogData, setDialogOpened, solveLoadingTask,
} from '../ui/uiActions';
import { UiComponetKeys } from '../ui/uiInitialState';
import { doLogin, setAppState, setIsAppSetup } from './appActions';
import { appInitialState } from './appInitialState';
import * as appApi from './appApi';
import { selectCurrentLanguage } from '../ui/uiSelectors';

const SESSION_STORAGE_KEYS = {
  jwt: 'jwt',
  loginTime: 'login_time',
  ttl: 'expires_in'
};

export function* checkToken() {
  const jwt = sessionStorage.getItem(SESSION_STORAGE_KEYS.jwt);
  if (!jwt) {
    return null;
  }
  const ttl = sessionStorage.getItem(SESSION_STORAGE_KEYS.ttl);
  const loginTime = sessionStorage.getItem(SESSION_STORAGE_KEYS.loginTime);
  const now = Date.now();
  const expire = parseInt(loginTime, 10) + parseInt(ttl, 10);
  let loginData = {
    jwt,
  };

  if (expire - now < ttl / 2 && expire - now >= 0) {
    loginData = yield call(appApi.refreshToken, jwt);
    sessionStorage.setItem(SESSION_STORAGE_KEYS.jwt, loginData.jwt);
    sessionStorage.setItem(SESSION_STORAGE_KEYS.loginTime, Date.now());
    sessionStorage.setItem(SESSION_STORAGE_KEYS.ttl, loginData.expires_in * 1000);
    // eslint-disable-next-line no-shadow
    let state = yield select((state) => state.app);
    state = {
      ...state,
      login: {
        jwt,
        expired: false
      },
    };
    yield put(setAppState(state));
    return loginData;
  }

  if (expire < now) {
    // eslint-disable-next-line no-shadow
    let state = yield select((state) => state.app);
    state = {
      ...state,
      login: {
        jwt: null,
        expired: true
      },
    };
    sessionStorage.clear();
    yield put(setDialogOpened(UiComponetKeys.SESSION_EXPIRED_DIALOG, true));
    yield put(setAppState(state));
    throw new Error('Sessione scaduta');
  }

  return loginData;
}

function* initApp() {
  const jwt = sessionStorage.getItem(SESSION_STORAGE_KEYS.jwt);
  if (jwt) {
    const ttl = sessionStorage.getItem(SESSION_STORAGE_KEYS.ttl);
    const loginTime = sessionStorage.getItem(SESSION_STORAGE_KEYS.loginTime);
    const now = Date.now();
    const expire = Number(loginTime + ttl);
    if (now < expire) {
      const loginData = yield checkToken();
      // eslint-disable-next-line no-shadow
      let state = yield select((state) => state.app);
      state = {
        ...state,
        login: {
          ...loginData,
        },
      };
      yield put(setAppState(state));
    } else {
      sessionStorage.clear();
    }
  }
  yield put(setIsAppSetup(true));
}

function* watchInitApp() {
  yield takeEvery(AppActionTypes.INIT, initApp);
}

function* traceNavigation({ payload }) {
  const loginData = yield select(selectLoginData);

  yield call(appApi.addTracingRecord, {
    userId: loginData.userId,
    ...payload,
  });
}

function* watchTraceNavigation() {
  yield takeEvery(AppActionTypes.ADD_TRACE_NAVIGATION, traceNavigation);
}

function* login({ payload: { email, password } }) {
  // TODO: save user data (bookings)
  try {
    yield put(addLoadingTask());
    const loginData = yield call(appApi.login, email, password);
    // eslint-disable-next-line no-shadow
    let state = yield select((state) => state.app);
    state = {
      ...state,
      login: {
        ...loginData,
      },
    };
    yield put(setAppState(state));
    sessionStorage.setItem(SESSION_STORAGE_KEYS.jwt, loginData.jwt);
    sessionStorage.setItem(SESSION_STORAGE_KEYS.loginTime, Date.now());
    sessionStorage.setItem(SESSION_STORAGE_KEYS.ttl, loginData.expires_in * 1000);
  } catch (e) {
    console.error(e);
    yield put(setDialogData(UiComponetKeys.ERROR_DIALOG, { msg: `Impossibile eseguire il login (${e.message})` }));
    yield put(setDialogOpened(UiComponetKeys.ERROR_DIALOG, true));
  } finally {
    yield put(solveLoadingTask());
  }
}

function* watchDoLogin() {
  yield takeEvery(AppActionTypes.DO_LOGIN, login);
}

function* signup({ payload }) {
  try {
    yield put(addLoadingTask());
    const curLang = yield select(selectCurrentLanguage);
    const signupOk = yield call(appApi.signup, payload, curLang);
    if (signupOk) {
      yield put(doLogin(payload.email, payload.password));
    }
  } catch (e) {
    console.error(e);
    yield put(setDialogData(UiComponetKeys.ERROR_DIALOG, { msg: `Impossibile eseguire la registrazione (${e.message})` }));
    yield put(setDialogOpened(UiComponetKeys.ERROR_DIALOG, true));
  } finally {
    yield put(solveLoadingTask());
  }
}

function* watchDoSignup() {
  yield takeEvery(AppActionTypes.DO_SIGNUP, signup);
}

function* logout() {
  sessionStorage.clear();
  yield put(setAppState(appInitialState));
  yield put(setIsAppSetup(true));
}

function* watchDoLogout() {
  yield takeEvery(AppActionTypes.DO_LOGOUT, logout);
}

export default [watchTraceNavigation, watchDoLogin, watchDoSignup, watchDoLogout, watchInitApp];
