import {
  Autocomplete,
  Box, Button, Checkbox, Divider, Paper, Table, TableBody,
  TableCell, TableContainer, TableHead, TableRow, TextField, Typography
} from '@mui/material';
import { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { BookingsActions } from './slices/bookings/bookings.actions';
import { bookingsSelectors } from './slices/bookings/bookings.selectors';
import { PitchesActions } from './slices/pitches/pitches.actions';
import { pitchesSelectors } from './slices/pitches/pitches.selectors';
import { TimeframesActions } from './slices/timeframes/timeframes.actions';
import { timeframesSelectors } from './slices/timeframes/timeframes.selectors';
import { setDialogData, setDialogOpened } from './slices/ui/uiActions';
import { UiComponetKeys } from './slices/ui/uiInitialState';
import { selectCurrentLanguage } from './slices/ui/uiSelectors';
import { getI18NText } from './utils/utils';

const INITAL_TIMEFRAME_VALUES = {
  pitchTimeframeHourStart: 0,
  pitchTimeframeHourEnd: 0,
  pitchTimeframeFullDay: false
};

export default function TimeframesComponent() {
  const pitchList = pitchesSelectors.list();
  const dispatch = useDispatch();

  const timeframes = useSelector(timeframesSelectors.list());
  const curLang = useSelector(selectCurrentLanguage);
  const [newTimeframe, setNewTimeframe] = useState(INITAL_TIMEFRAME_VALUES);
  const [dayToLock, setDayToLock] = useState('');
  const [pitchToLockId, setPitchToLockId] = useState();
  const lockedBookings = useSelector(bookingsSelectors.lockedBookingList());
  const pitches = useSelector(pitchList);
  const availablePitches = useMemo(() => pitches.map((pitch) => ({
    label: pitch.pitchName,
    pitchId: pitch.pitchId
  })), [pitches]);

  useEffect(() => {
    dispatch(TimeframesActions.getList());
    dispatch(BookingsActions.getLockedBookings());
    dispatch(PitchesActions.getList());
  }, [dispatch]);

  const onUpdateHourStart = (timeframe, hour) => {
    const minutes = hour * 60;
    dispatch(TimeframesActions.setById({
      payload: {
        ...timeframe,
        pitchTimeframeHourStart: minutes
      },
      id: timeframe.pitchTimeframeId
    }));
  };

  const onUpdateHourEnd = (timeframe, hour) => {
    const minutes = hour * 60;
    dispatch(TimeframesActions.setById({
      payload: {
        ...timeframe,
        pitchTimeframeHourEnd: minutes
      },
      id: timeframe.pitchTimeframeId
    }));
  };

  const onChangeFullDay = (timeframe, pitchTimeframeFullDay) => {
    dispatch(TimeframesActions.setById({
      payload: {
        ...timeframe,
        pitchTimeframeFullDay: pitchTimeframeFullDay ? 1 : 0
      },
      id: timeframe.pitchTimeframeId
    }));
  };

  const onChangePitchId = (timeframe, pitchId) => {
    dispatch(TimeframesActions.setById({
      payload: {
        ...timeframe,
        pitchTimeframe_pitchId: pitchId
      },
      id: timeframe.pitchTimeframeId
    }));
  };

  const onUpdatePrice = (timeframe, price) => {
    dispatch(TimeframesActions.setById({
      payload: {
        ...timeframe,
        pitchTimeframePrice: price
      },
      id: timeframe.pitchTimeframeId
    }));
  };

  const onUpdateTimeframe = (timeframe) => {
    dispatch(TimeframesActions.update(timeframe, timeframe.pitchTimeframeId));
  };

  const onCreateTimeframe = (timeframe) => {
    setNewTimeframe({});
    dispatch(TimeframesActions.create(timeframe));
  };

  const onLockDay = () => {
    try {
      const parsedDayToLock = new Date(dayToLock);
      const timeframeToLock = timeframes.find(
        (t) => t.pitchTimeframeFullDay === 1 && t.pitchTimeframe_pitchId === pitchToLockId
      );
      if (!timeframeToLock) {
        dispatch(setDialogData(
          UiComponetKeys.ERROR_DIALOG,
          { msg: 'Nessuna fascia oraria trovata per bloccare il giorno intero. Creare una fascia oraria che copre tutto il giorno e poi aggiungere il blocco.' }
        ));
        dispatch(setDialogOpened(UiComponetKeys.ERROR_DIALOG, true));
        return;
      }
      dispatch(BookingsActions.createLockedBooking({
        bookingDay: parsedDayToLock.toISOString(),
        bookingPitchTimeframeId: timeframeToLock.pitchTimeframeId,
        bookingPitchId: pitchToLockId
      }));
    } catch {
      dispatch(setDialogData(
        UiComponetKeys.ERROR_DIALOG,
        { msg: 'Data scelta non valida.' }
      ));
      dispatch(setDialogOpened(UiComponetKeys.ERROR_DIALOG, true));
    }
  };

  const onDeleteBookingLock = (booking) => (
    dispatch(BookingsActions.deleteLockedBooking(booking.bookingId))
  );

  return (
    <Box>
      <TableContainer component={Paper} sx={{ margin: 1 }}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell colSpan={7} align="center">
                <Typography>Fasce orarie</Typography>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>ID</TableCell>
              <TableCell align="center">Giornata intera</TableCell>
              <TableCell align="left">Ora inizio</TableCell>
              <TableCell align="left">Ora fine</TableCell>
              <TableCell align="left">Prezzo</TableCell>
              <TableCell align="center">Piazzola</TableCell>
              <TableCell align="center">Azioni</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {timeframes.map((timeframe) => (
              <TableRow
                key={`${timeframe.pitchTimeframeId}`}
                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
              >
                <TableCell component="th" scope="row">
                  {timeframe.pitchTimeframeId}
                </TableCell>
                <TableCell align="center">
                  <Checkbox
                    inputProps={{ 'aria-label': 'Giornata intera' }}
                    checked={!!timeframe.pitchTimeframeFullDay}
                    onChange={(e) => onChangeFullDay(timeframe, e.target.checked)}
                  />
                </TableCell>
                <TableCell align="left">
                  <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <TextField flex={1} type="number" value={timeframe.pitchTimeframeHourStart / 60} onChange={(e) => onUpdateHourStart(timeframe, e.target.value)} />
                    <Typography flex={4} textAlign="left">:00</Typography>
                  </Box>
                </TableCell>
                <TableCell align="left">
                  <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <TextField flex={1} type="number" value={timeframe.pitchTimeframeHourEnd / 60} onChange={(e) => onUpdateHourEnd(timeframe, e.target.value)} />
                    <Typography flex={4} textAlign="left">:00</Typography>
                  </Box>
                </TableCell>
                <TableCell align="left">
                  <TextField
                    sx={{ width: '100%', marginTop: '1rem' }}
                    label={getI18NText('pitch.pitch_price', curLang)}
                    value={timeframe.pitchTimeframePrice}
                    InputLabelProps={{ shrink: true }}
                    onChange={(e) => onUpdatePrice(timeframe, e.target.value)}
                  />
                </TableCell>
                <TableCell>
                  {
                    availablePitches.length > 0
                      ? (
                        <Autocomplete
                          sx={{
                            width: '100%'
                          }}
                          renderInput={(params) => (
                            // eslint-disable-next-line react/jsx-props-no-spreading
                            <TextField {...params} label="Scegli" variant="standard" />
                          )}
                          options={availablePitches}
                          value={(
                            availablePitches.find(
                              (e) => e.pitchId === timeframe.pitchTimeframe_pitchId
                            )
                          )}
                          onChange={(_e, { pitchId }) => onChangePitchId(timeframe, pitchId)}
                        />
                      )
                      : null
                  }
                </TableCell>
                <TableCell>
                  <Button variant="contained" onClick={() => onUpdateTimeframe(timeframe)}>
                    {getI18NText('actions.update', curLang)}
                  </Button>
                </TableCell>
              </TableRow>
            ))}
            {
              timeframes.length === 0
                ? (
                  <TableRow>
                    <TableCell align="center" colSpan={7}>Non ci sono elementi da mostrare.</TableCell>
                  </TableRow>
                )
                : null
            }
            <TableRow>
              <TableCell colSpan={7} align="center">
                Aggiungi fascia oraria
              </TableCell>
            </TableRow>
            <TableRow
              key="new_timeframe"
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
              <TableCell />
              <TableCell align="center">
                <Checkbox
                  inputProps={{ 'aria-label': 'Giornata intera' }}
                  checked={!!newTimeframe.pitchTimeframeFullDay}
                  onChange={(e) => setNewTimeframe({
                    ...newTimeframe,
                    pitchTimeframeFullDay: e.target.checked
                  })}
                />
              </TableCell>
              <TableCell align="left">
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                  <TextField
                    flex={1}
                    type="number"
                    value={newTimeframe.pitchTimeframeHourStart / 60}
                    onChange={(e) => setNewTimeframe({
                      ...newTimeframe,
                      pitchTimeframeHourStart: e.target.value * 60
                    })}
                  />
                  <Typography flex={4} textAlign="left">:00</Typography>
                </Box>
              </TableCell>
              <TableCell align="left">
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                  <TextField
                    flex={1}
                    type="number"
                    value={newTimeframe.pitchTimeframeHourEnd / 60}
                    onChange={(e) => setNewTimeframe({
                      ...newTimeframe,
                      pitchTimeframeHourEnd: e.target.value * 60
                    })}
                  />
                  <Typography flex={4} textAlign="left">:00</Typography>
                </Box>
              </TableCell>
              <TableCell align="left">
                <TextField
                  sx={{ width: '100%', marginTop: '1rem' }}
                  label={getI18NText('pitch.pitch_price', curLang)}
                  value={newTimeframe.pitchTimeframePrice}
                  InputLabelProps={{ shrink: true }}
                  onChange={(e) => setNewTimeframe(
                    {
                      ...newTimeframe,
                      pitchTimeframePrice: e.target.value
                    }
                  )}
                />
              </TableCell>
              <TableCell>
                <Autocomplete
                  sx={{
                    width: '100%'
                  }}
                  renderInput={(params) => (
                    // eslint-disable-next-line react/jsx-props-no-spreading
                    <TextField {...params} label="Scegli" variant="standard" />
                  )}
                  options={availablePitches}
                  value={(
                    availablePitches.find((e) => e.pitchId === newTimeframe.pitchTimeframe_pitchId)
                  )}
                  onChange={(_e, { pitchId }) => setNewTimeframe({
                    ...newTimeframe,
                    pitchTimeframe_pitchId: pitchId,
                  })}
                />
              </TableCell>
              <TableCell>
                <Button variant="contained" onClick={() => onCreateTimeframe(newTimeframe)}>
                  {getI18NText('actions.add', curLang)}
                </Button>
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>

      <Divider sx={{ marginX: '2rem' }} />

      <TableContainer component={Paper} sx={{ margin: 1 }}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell colSpan={4} align="center">
                <Typography>Giorni bloccati</Typography>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>ID</TableCell>
              <TableCell align="left">Data</TableCell>
              <TableCell>Piazzola</TableCell>
              <TableCell align="center">Azioni</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>

            {
              lockedBookings.map((booking) => (
                <TableRow
                  key={`booking-locked-${booking.bookingId}`}
                  sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                >
                  <TableCell>
                    {booking.bookingId}
                  </TableCell>
                  <TableCell align="left">
                    {new Date(booking.bookingDay).toLocaleDateString()}
                  </TableCell>
                  <TableCell>
                    {
                      availablePitches
                        .find((e) => e.pitchId === booking.booking_pitchTimeframeId)?.label
                    }
                  </TableCell>
                  <TableCell align="center">
                    <Button
                      color="error"
                      variant="contained"
                      onClick={() => onDeleteBookingLock(booking)}
                    >
                      {getI18NText('actions.delete', curLang)}
                    </Button>
                  </TableCell>
                </TableRow>
              ))
            }

            {
              lockedBookings.length === 0
                ? (
                  <TableRow>
                    <TableCell align="center" colSpan={8}>Non ci sono elementi da mostrare.</TableCell>
                  </TableRow>
                )
                : null
            }

            <TableRow>
              <TableCell colSpan={4} align="center">
                Aggiungi blocco
              </TableCell>
            </TableRow>

            <TableRow>
              <TableCell>
                <TextField
                  sx={{
                    width: '100%'
                  }}
                  InputLabelProps={{ shrink: true }}
                  label="Scegli giorno"
                  type="date"
                  value={dayToLock}
                  onChange={(e) => setDayToLock(e.target.value)}
                />
              </TableCell>
              <TableCell colSpan={2}>
                <Autocomplete
                  sx={{
                    width: '100%'
                  }}
                  renderInput={(params) => (
                    // eslint-disable-next-line react/jsx-props-no-spreading
                    <TextField {...params} label="Scegli la piazzola" variant="standard" />
                  )}
                  options={availablePitches}
                  onChange={(e, { pitchId }) => setPitchToLockId(pitchId)}
                />
              </TableCell>
              <TableCell align="center">
                <Button variant="contained" onClick={onLockDay}>BLOCCA</Button>
              </TableCell>
            </TableRow>

          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
}
