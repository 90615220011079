import { Provider } from 'react-redux';
import { store as reduxAppStore } from './app/slices/main';
import { LoadingDialogComponent } from './app/ui/LoadingDialogComponent';
import { ActionDialogComponent } from './app/ui/ActionDialogComponent';
import { UiComponetKeys } from './app/slices/ui/uiInitialState';
import AppNavigatorComponent from './app/AppNavigatorComponent';
import './App.css';

function AppContainer() {
  const onNavigateLogin = () => {
    window.location = '/login';
  };

  return (
    <>

      <LoadingDialogComponent />
      <ActionDialogComponent
        dialogKey={UiComponetKeys.SESSION_EXPIRED_DIALOG}
        callback={onNavigateLogin}
      />
      <ActionDialogComponent dialogKey={UiComponetKeys.ERROR_DIALOG} />
      <ActionDialogComponent dialogKey={UiComponetKeys.MESSAGE_DIALOG} />
      <AppNavigatorComponent />

    </>
  );
}

function App() {
  return (
    <Provider store={reduxAppStore}>
      <AppContainer />
    </Provider>
  );
}

export default App;
