import { call, put, takeEvery } from 'redux-saga/effects';
import { SagasFactory } from '../factories/sagas.factory';
import { BookingsActions } from './bookings.actions';
import { BookingsApi } from './bookings.api';
import {
  addLoadingTask, setDialogData, setDialogOpened, solveLoadingTask
} from '../ui/uiActions';
import { UiComponetKeys } from '../ui/uiInitialState';
import bookingsActionTypes from './bookings.actionTypes';

export class BookingsSagasFactory extends SagasFactory {
  * getLockedBookings() {
    try {
      yield put(addLoadingTask());
      yield this.checkLogin();

      const list = yield call(this.apiClient.getLockedBookings.bind(this.apiClient));
      yield put(this.actions.setLockedBookings(list));
    } catch (e) {
      console.error(e);
      yield put(setDialogData(UiComponetKeys.ERROR_DIALOG, { msg: `Impossibile recuperare la lista di date bloccate (${e.message})` }));
      yield put(setDialogOpened(UiComponetKeys.ERROR_DIALOG, true));
    } finally {
      yield put(solveLoadingTask());
    }
  }

  * watchGetLockedBookings() {
    yield takeEvery(this.actionTypes.GET_LOCKED_BOOKINGS, this.getLockedBookings.bind(this));
  }

  * createLockedBooking({ payload }) {
    try {
      yield put(addLoadingTask());
      yield this.checkLogin();

      yield call(this.apiClient.createLockedBooking.bind(this.apiClient), payload);
      yield put(this.actions.getLockedBookings());

      yield put(setDialogData(UiComponetKeys.ERROR_DIALOG, { msg: 'Blocco inserito correttamente.' }));
      yield put(setDialogOpened(UiComponetKeys.ERROR_DIALOG, true));
    } catch (e) {
      console.error(e);
      yield put(setDialogData(UiComponetKeys.ERROR_DIALOG, { msg: `Impossibile aggiungere il blocco (${e.message})` }));
      yield put(setDialogOpened(UiComponetKeys.ERROR_DIALOG, true));
    } finally {
      yield put(solveLoadingTask());
    }
  }

  * watchCreateLockedBooking() {
    yield takeEvery(this.actionTypes.CREATE_LOCKED_BOOKING, this.createLockedBooking.bind(this));
  }

  * deleteLockedBooking({ payload: { id } }) {
    try {
      yield put(addLoadingTask());
      yield this.checkLogin();

      yield call(this.apiClient.deleteLockedBooking.bind(this.apiClient), id);

      yield put(setDialogData(UiComponetKeys.ERROR_DIALOG, { msg: 'Blocco cancellato correttamente.' }));
      yield put(setDialogOpened(UiComponetKeys.ERROR_DIALOG, true));

      yield put(this.actions.getLockedBookings());
    } catch (e) {
      console.error(e);
      yield put(setDialogData(UiComponetKeys.ERROR_DIALOG, { msg: `Impossibile cancellare il blocco (${e.message})` }));
      yield put(setDialogOpened(UiComponetKeys.ERROR_DIALOG, true));
    } finally {
      yield put(solveLoadingTask());
    }
  }

  * watchDeleteLockedBooking() {
    yield takeEvery(this.actionTypes.DELETE_LOCKED_BOOKING, this.deleteLockedBooking.bind(this));
  }

  sliceSagas() {
    return [
      this.watchGetLockedBookings.bind(this),
      this.watchCreateLockedBooking.bind(this),
      this.watchDeleteLockedBooking.bind(this),
    ];
  }
}

const factory = new BookingsSagasFactory('bookings', 'bookings', BookingsApi, BookingsActions, bookingsActionTypes);
export const BookingsSagas = factory.getSagas();
