import {
  Box, Paper, Table, TableBody, TableCell,
  TableContainer, TableHead, TableRow, TextField, Typography
} from '@mui/material';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { APP_LANGS } from '../constants';
import { BookingsActions } from './slices/bookings/bookings.actions';
import { bookingsSelectors } from './slices/bookings/bookings.selectors';
import { selectCurrentLanguage } from './slices/ui/uiSelectors';
import { useQuery } from './utils/useQuery';

const INITIAL_FILTERS = {
  email: '',
  name: '',
  latname: '',
  fiscalCode: '',
  startDate: '',
  endDate: '',
};

export default function BookingsComponent() {
  const dispatch = useDispatch();

  const bookings = useSelector(bookingsSelectors.list());
  const curLang = useSelector(selectCurrentLanguage);
  const [filteredBookings, setFilteredBookings] = useState(bookings);
  const queryParams = useQuery();

  if (queryParams.get('userEmail')) {
    INITIAL_FILTERS.email = queryParams.get('userEmail');
  }

  const [filters, setFilters] = useState(INITIAL_FILTERS);

  useEffect(() => {
    dispatch(BookingsActions.getList());
  }, []);

  useEffect(() => {
    setFilteredBookings(bookings.filter((booking) => (
      booking.user.userEmail.includes(filters.email)
      && booking.user.userName.includes(filters.name)
      && booking.user.userLastname.includes(filters.latname)
      && booking.user.userFiscalCode.includes(filters.fiscalCode)
      && (filters.startDate !== '' ? new Date(booking.bookingDay) >= new Date(filters.startDate) : true)
      && (filters.endDate !== '' ? new Date(booking.bookingDay) <= new Date(filters.endDate) : true)
    )));
  }, [filters, bookings]);

  const onChangeStartDate = (e) => setFilters({ ...filters, startDate: e.target.value });
  const onChangeEndDate = (e) => setFilters({ ...filters, endDate: e.target.value });
  const onChangeEmail = (e) => setFilters({ ...filters, email: e.target.value });
  const onChangeName = (e) => setFilters({ ...filters, name: e.target.value });
  const onChangeLastname = (e) => setFilters({ ...filters, latname: e.target.value });
  const onChangeFiscalCode = (e) => setFilters({ ...filters, fiscalCode: e.target.value });

  return (
    <Box>
      <Box sx={{
        marginY: '2rem'
      }}
      >
        <Typography sx={{ padding: 1 }}>Filtra per</Typography>
        <Box sx={{
          display: 'flex',
        }}
        >

          <TextField sx={{ padding: 1, alignSelf: 'center' }} label="Email" value={filters.email} onChange={onChangeEmail} />
          <TextField sx={{ padding: 1, alignSelf: 'center' }} label="Nome" value={filters.name} onChange={onChangeName} />
          <TextField sx={{ padding: 1, alignSelf: 'center' }} label="Cognome" value={filters.lastName} onChange={onChangeLastname} />
          <TextField sx={{ padding: 1, alignSelf: 'center' }} label="Codice fiscale" value={filters.fiscalCode} onChange={onChangeFiscalCode} />
          <TextField
            InputLabelProps={{ shrink: true }}
            type="date"
            sx={{ padding: 1, alignSelf: 'center' }}
            label="Dal giorno"
            value={filters.startDate}
            onChange={onChangeStartDate}
          />
          <TextField
            InputLabelProps={{ shrink: true }}
            type="date"
            sx={{ padding: 1, alignSelf: 'center' }}
            label="Al giorno"
            value={filters.endDate}
            onChange={onChangeEndDate}
          />

        </Box>
      </Box>
      <TableContainer component={Paper} sx={{ margin: 1 }}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Email</TableCell>
              <TableCell align="right">Nome</TableCell>
              <TableCell align="right">Cognome</TableCell>
              <TableCell align="right">Codice fiscale</TableCell>

              <TableCell align="right">Piazzola</TableCell>

              <TableCell align="right">Data prenotazione</TableCell>
              <TableCell align="right">Giornata intera</TableCell>
              <TableCell align="right">Ora inizio</TableCell>
              <TableCell align="right">Ora fine</TableCell>

            </TableRow>
          </TableHead>
          <TableBody>
            {filteredBookings.map((booking) => (
              <TableRow
                key={`${booking.bookingId}${booking.user.userEmail}${booking.user.userId}`}
                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
              >
                <TableCell component="th" scope="row">
                  {booking.user.userEmail}
                </TableCell>
                <TableCell align="right">{booking.user.userName}</TableCell>
                <TableCell align="right">{booking.user.userLastname}</TableCell>
                <TableCell align="right">{booking.user.userFiscalCode}</TableCell>

                <TableCell align="right">{booking.pitch.pitchName}</TableCell>

                <TableCell align="right">{new Date(booking.bookingDay).toLocaleDateString(APP_LANGS[curLang].langKey)}</TableCell>
                <TableCell align="right">{booking.timeframe.pitchTimeframeFullDay ? 'Si' : 'No'}</TableCell>
                <TableCell align="right">{booking.timeframe.pitchTimeframeFullDay ? '-' : `${booking.timeframe.pitchTimeframeHourStart / 60}:00`}</TableCell>
                <TableCell align="right">{booking.timeframe.pitchTimeframeFullDay ? '-' : `${booking.timeframe.pitchTimeframeHourEnd / 60}:00`}</TableCell>
              </TableRow>
            ))}
            {
              filteredBookings.length === 0
                ? (
                  <TableRow>
                    <TableCell align="center" colSpan={8}>Non ci sono elementi da mostrare.</TableCell>
                  </TableRow>
                )
                : null
            }
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
}
