import {
  Card, CardActions, CardContent, CardMedia, Typography,
} from '@mui/material';
import { useCallback, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { PlacesActions } from './slices/places/places.actions';
import { placesSelectors } from './slices/places/places.selectors';

export default function PlacesComponent() {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const places = useSelector(placesSelectors.list());

  useEffect(() => {
    dispatch(PlacesActions.getList());
  }, []);

  useEffect(() => {
    if (places.length === 1) {
      navigate(`/places/${places[0].placeId}/pitches/list`);
    }
  }, [places]);

  /**
     * @param {number} placeId
     * @param {string} placeName
     */
  const onGoToPitches = useCallback((placeId, placeName) => {
    navigate('/pitches', { replace: true, state: { placeId, placeName } });
  });

  const placesList = useMemo(() => places.map((place) => (
    <Card key={`home-place-id-${place.id}`} onClick={() => onGoToPitches(place.id, place.name)}>
      <CardMedia
        component="img"
        height="200"
        image={place.preview}
        alt={place.name}
      />
      <CardContent>
        <Typography gutterBottom variant="h5" component="div">
          {place.name}
        </Typography>
      </CardContent>
      <CardActions />
    </Card>
  )), [places]);

  return placesList;
}
