import { Box } from '@mui/material';
import PropTypes from 'prop-types';

export default function HoverComponent({ front, back }) {
  return (
    <Box className="hover">
      <Box className="hover front">
        {front}
      </Box>
      <Box className="hover back">
        {back}
      </Box>
    </Box>
  );
}

HoverComponent.propTypes = {
  front: PropTypes.shape({}).isRequired,
  back: PropTypes.shape({}).isRequired,
};
