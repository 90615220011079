import { takeEvery, select, put } from 'redux-saga/effects';
import UiActionTypes from './uiActionTypes';
import { selectLoadingTasks } from './uiSelectors';
import {
  setDialogOpened, solveLoadingTaskSlice, addLoadingTaskSlice, setAppLangSlice,
} from './uiActions';
import { setAppState } from '../app/appActions';
import { UiComponetKeys } from './uiInitialState';

function* addLoadingTask() {
  yield put(addLoadingTaskSlice());
  yield put(setDialogOpened(UiComponetKeys.LOADING_DIALOG, true));
}

function* watchAddLoadingTask() {
  yield takeEvery(UiActionTypes.ADD_LOADING_TASK, addLoadingTask);
}

function* solveLoadingTask() {
  yield put(solveLoadingTaskSlice());
  const loadingTasks = yield select(selectLoadingTasks);
  if (loadingTasks.length === 0) {
    yield put(setDialogOpened(UiComponetKeys.LOADING_DIALOG, false));
  }
}

function* watchSolveLoadingTask() {
  yield takeEvery(UiActionTypes.SOLVE_LOADING_TASK, solveLoadingTask);
}

function* setAppLang({ payload: lang }) {
  yield put(setAppLangSlice(lang));
  let state = yield select((s) => s.app);
  state = {
    ...state,
    lang,
  };
  yield put(setAppState(state));
}

function* watchSetAppLang() {
  yield takeEvery(UiActionTypes.SET_APP_LANG, setAppLang);
}

export default [watchAddLoadingTask, watchSolveLoadingTask, watchSetAppLang];
