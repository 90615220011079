import {
  applyMiddleware, combineReducers, compose, createStore
} from 'redux';
// eslint-disable-next-line import/no-extraneous-dependencies
import { composeWithDevTools } from 'redux-devtools-extension';
import createSagaMiddleware from 'redux-saga';

import { uiReducers as ui } from './ui/uiReducers';
import { appReducers as app } from './app/appReducers';

import appSagas from './app/appSagas';
import uiSagas from './ui/uiSagas';
import { placesReducer } from './places/places.reducers';
import { pitchesReducer } from './pitches/pitches.reducers';
import { PlacesSagas } from './places/places.sagas';
import { PitchesSagas } from './pitches/pitches.sagas';
import { UsersSagas } from './users/users.sagas';
import { BookingsSagas } from './bookings/bookings.sagas';
import { TimeframesSagas } from './timeframes/timeframes.sagas';
import { usersReducer } from './users/users.reducers';
import { bookingsReducer } from './bookings/bookings.reducers';
import { timeframesReducer } from './timeframes/timeframes.reducers';

const sagaMiddleware = createSagaMiddleware();
const rootReducer = combineReducers({
  ui,
  app,
  places: placesReducer,
  pitches: pitchesReducer,
  users: usersReducer,
  bookings: bookingsReducer,
  timeframes: timeframesReducer
});

const composeEnhancers = process.env.NODE_ENV === 'development'
  ? composeWithDevTools({
    realtime: true,
    name: 'CarpFishing',
    hostname: 'localhost',
    port: 8000 // the port your remotedev server is running at
  })
  : compose;

const store = createStore(rootReducer, composeEnhancers(applyMiddleware(sagaMiddleware)));

[
  ...appSagas,
  ...uiSagas,
  ...PlacesSagas,
  ...PitchesSagas,
  ...UsersSagas,
  ...BookingsSagas,
  ...TimeframesSagas
].forEach((saga) => sagaMiddleware.run(saga));

export { store };
