/* eslint-disable no-underscore-dangle */
export class SelectorsFactory {
  sliceName;

  constructor(sliceName) {
    if (!sliceName) {
      throw new Error('No slice name given');
    }
    this.sliceName = sliceName;
  }

  _list(state) {
    return state[this.sliceName].list;
  }

  list() {
    return this._list.bind(this);
  }

  _byId(id) {
    const { sliceName } = this;
    return (state) => state[sliceName].list.find((e) => e.id === id);
  }

  byId() {
    return this._byId.bind(this);
  }
}
