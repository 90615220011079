import { ActionTypesFactory, DEFAULT_ACTION_TYPES } from '../factories/actionTypes.factory';

export default ActionTypesFactory(
  'BOOKINGS',
  DEFAULT_ACTION_TYPES,
  {
    GET_LOCKED_BOOKINGS: 'BOOKINGS/GET_LOCKED_BOOKINGS',
    SET_LOCKED_BOOKINGS: 'BOOKINGS/SET_LOCKED_BOOKINGS',
    CREATE_LOCKED_BOOKING: 'BOOKINGS/CREATE_LOCKED_BOOKING',
    DELETE_LOCKED_BOOKING: 'BOOKINGS/DELETE_LOCKED_BOOKING',
  }
);
