import UiActionTypes from './uiActionTypes';

export const addDialog = (key, data) => ({
  type: UiActionTypes.ADD_DIALOG,
  payload: {
    key,
    data,
  },
});

export const setDialogOpened = (key, opened) => ({
  type: UiActionTypes.SET_DIALOG_OPENED,
  payload: {
    key,
    opened,
  },
});

export const setDialogData = (key, data) => ({
  type: UiActionTypes.SET_DIALOG_DATA,
  payload: {
    key,
    data,
  },
});

export const resetDialog = (key) => ({
  type: UiActionTypes.RESET_DIALOG,
  payload: { key },
});

export const addLoadingTask = () => ({
  type: UiActionTypes.ADD_LOADING_TASK,
});

export const solveLoadingTask = () => ({
  type: UiActionTypes.SOLVE_LOADING_TASK,
});

export const addLoadingTaskSlice = () => ({
  type: UiActionTypes.ADD_LOADING_TASK_SLICE,
});

export const solveLoadingTaskSlice = () => ({
  type: UiActionTypes.SOLVE_LOADING_TASK_SLICE,
});

export const setAppLang = (lang) => ({
  type: UiActionTypes.SET_APP_LANG,
  payload: lang,
});

export const setAppLangSlice = (lang) => ({
  type: UiActionTypes.SET_APP_LANG_SLICE,
  payload: lang,
});
