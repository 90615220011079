export const DEFAULT_ACTION_TYPES = {
  SET: 'SET',
  REPLACE: 'REPLACE',
  RESET: 'RESET',
  CLEAR: 'CLEAR',
  GET_LIST: 'GET_LIST',
  ADD: 'ADD',
  SET_LIST: 'SET_LIST',
  GET_BY_ID: 'GET_BY_ID',
  SET_BY_ID: 'SET_BY_ID',
  CREATE: 'CREATE',
  UPDATE: 'UPDATE',
};

export const ActionTypesFactory = (
  sliceName,
  actions = DEFAULT_ACTION_TYPES,
  otherActions = {}
) => ({
  ...Object.keys(actions).reduce((dict, key) => ({
    ...dict,
    [key]: `${sliceName.toUpperCase()}/${actions[key]}`
  }), {}),
  ...otherActions,
});
