import { SelectorsFactory } from '../factories/selectors.factory';

export class TimeframesSelectors extends SelectorsFactory {
  // eslint-disable-next-line no-underscore-dangle
  _byId(id) {
    const { sliceName } = this;
    return (state) => state[sliceName].list.find((e) => e.pitchTimeframeId === id);
  }
}

export const timeframesSelectors = new TimeframesSelectors('timeframes');
