import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  BrowserRouter, Navigate, Route, Routes, Link
} from 'react-router-dom';
import {
  Box,
  CircularProgress,
  createTheme,
  Divider,
  Drawer, ListItem, ListItemIcon, ListItemText, ThemeProvider, Typography
} from '@mui/material';

import LoginIcon from '@mui/icons-material/Login';
import PlaceIcon from '@mui/icons-material/Place';
import BeachAccessIcon from '@mui/icons-material/BeachAccess';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import CardMembershipIcon from '@mui/icons-material/CardMembership';
import PeopleIcon from '@mui/icons-material/People';
import LogoutIcon from '@mui/icons-material/Logout';

import PitchComponent from './PitchComponent';
import { selectIsAppSetup, selectLoginData } from './slices/app/appSelectors';
import LoginComponent from './LoginComponent';
import { selectCurrentLanguage } from './slices/ui/uiSelectors';
import { getI18NText } from './utils/utils';
import PitchesComponent from './PitchesComponent';
import PlacesComponent from './PlacesComponent';
import { placesSelectors } from './slices/places/places.selectors';
import UsersComponent from './UsersComponent';
import BookingsComponent from './BookingsComponent';
import TimeframesComponent from './TimeframesComponent';
import { doLogout, initApp } from './slices/app/appActions';

const theme = createTheme({
  components: {
    MuiDrawer: {
      styleOverrides: {
        root: {
          height: '100vh'
        },
        paper: {
          width: '15vw',
        }
      }
    }
  },
});

export default function AppNavigatorComponent() {
  const dispatch = useDispatch();

  const loginData = useSelector(selectLoginData);
  const curLang = useSelector(selectCurrentLanguage);
  const places = useSelector(placesSelectors.list());
  const isAppSetup = useSelector(selectIsAppSetup);

  useEffect(() => {
    dispatch(initApp());
  }, [dispatch]);

  if (!isAppSetup) {
    return <CircularProgress />;
  }

  return (
    <ThemeProvider theme={theme}>
      <BrowserRouter>

        <div style={{ display: 'flex' }}>
          <Drawer anchor="left" open variant="permanent">
            <Box sx={{
              padding: 1
            }}
            >
              <Typography variant="h6">
                Carpfishing Backoffice
              </Typography>
            </Box>
            <Divider />
            {
              loginData.jwt === null
                ? (
                  <Link to="/login">
                    <ListItem button key="login">
                      <ListItemIcon>
                        <LoginIcon />
                      </ListItemIcon>
                      <ListItemText primary={getI18NText('ui.main_drawer.login', curLang)} />
                    </ListItem>
                  </Link>
                )
                : (
                  <>

                    {
                      places.length > 1
                        ? (
                          <Link to="/places/list">
                            <ListItem button key="places">
                              <ListItemIcon>
                                <PlaceIcon />
                              </ListItemIcon>
                              <ListItemText primary={getI18NText('ui.main_drawer.places', curLang)} />
                            </ListItem>
                          </Link>
                        )
                        : null
                    }

                    <Link to="/pitches/list">
                      <ListItem button key="pitches">
                        <ListItemIcon>
                          <BeachAccessIcon />
                        </ListItemIcon>
                        <ListItemText primary={getI18NText('ui.main_drawer.pitches', curLang)} />
                      </ListItem>
                    </Link>
                    <Link to="/timeframes/list">
                      <ListItem button key="timeframes">
                        <ListItemIcon>
                          <AccessTimeIcon />
                        </ListItemIcon>
                        <ListItemText primary={getI18NText('ui.main_drawer.timeframes', curLang)} />
                      </ListItem>
                    </Link>
                    <Link to="/bookings/list">
                      <ListItem button key="bookings">
                        <ListItemIcon>
                          <CardMembershipIcon />
                        </ListItemIcon>
                        <ListItemText primary={getI18NText('ui.main_drawer.bookings', curLang)} />
                      </ListItem>
                    </Link>
                    <Link to="/users/list">
                      <ListItem button key="users">
                        <ListItemIcon>
                          <PeopleIcon />
                        </ListItemIcon>
                        <ListItemText primary={getI18NText('ui.main_drawer.users', curLang)} />
                      </ListItem>
                    </Link>
                    <ListItem button key="logout" onClick={() => dispatch(doLogout())}>
                      <ListItemIcon>
                        <LogoutIcon />
                      </ListItemIcon>
                      <ListItemText primary={getI18NText('ui.main_drawer.logout', curLang)} />
                    </ListItem>
                  </>
                )
            }
          </Drawer>

          <div style={{
            marginLeft: '15vw',
            width: '85vw',
            display: 'flex',
            justifyContent: 'center'
          }}
          >
            <Routes>
              {
                loginData.jwt === null
                  ? (
                    <>
                      <Route path="/login" element={<LoginComponent />} />
                      <Route path="*" element={<Navigate to="/login" />} />
                    </>
                  )
                  : (
                    <>
                      <Route path="/places/list" index element={<PlacesComponent />} />

                      <Route path="/timeframes/list" index element={<TimeframesComponent />} />

                      <Route path="/places/:placeId/pitches/add" element={<PitchComponent />} />
                      <Route path="/places/:placeId/pitches/:pitchId" element={<PitchComponent />} />
                      <Route path="/places/:placeId/pitches/list" index element={<PitchesComponent />} />

                      <Route path="/users/list" index element={<UsersComponent />} />

                      <Route path="/bookings/list" index element={<BookingsComponent />} />

                      <Route path="*" element={<Navigate to="/places/list" />} />
                    </>
                  )
              }
            </Routes>
          </div>
        </div>
      </BrowserRouter>
    </ThemeProvider>
  );
}
