import UiActionTypes from './uiActionTypes';
import { uiInitialState } from './uiInitialState';

// eslint-disable-next-line default-param-last
export const uiReducers = (state = uiInitialState, action) => {
  switch (action.type) {
    case UiActionTypes.ADD_DIALOG:
      return {
        ...state,
        [action.payload.key]: {
          opened: false,
          ...action.payload.data,
        },
      };
    case UiActionTypes.SET_DIALOG_OPENED:
      return {
        ...state,
        [action.payload.key]: {
          ...state[action.payload.key],
          opened: action.payload.opened,
        },
      };
    case UiActionTypes.SET_DIALOG_DATA:
      return {
        ...state,
        [action.payload.key]: {
          ...state[action.payload.key],
          data: action.payload.data,
        },
      };
    case UiActionTypes.RESET_DIALOG:
      return {
        ...state,
        [action.payload.key]: {
          open: false,
          data: null,
        },
      };
    case UiActionTypes.ADD_LOADING_TASK_SLICE:
      return {
        ...state,
        loadingTasks: [...state.loadingTasks, true],
      };
    case UiActionTypes.SOLVE_LOADING_TASK_SLICE:
      return {
        ...state,
        loadingTasks: (state.loadingTasks.length > 0
          ? state.loadingTasks.slice(1)
          : state.loadingTasks)
      };
    case UiActionTypes.SET_APP_LANG_SLICE:
      return {
        ...state,
        currentLang: action.payload,
      };
    default:
      return state;
  }
};
