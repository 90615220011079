import { ApiFactory } from '../factories/api.factory';

export class PitchesApi extends ApiFactory {
  static validate(payload, id) {
    if (!id && !payload.carouselPics) {
      throw new Error("La piazzola necessita di almeno un'immagine");
    }
  }

  async createOrUpdate(payload, id = null) {
    PitchesApi.validate(payload, id);
    const formData = new FormData();
    Object.keys(payload).filter((e) => e !== 'carouselPics' && e !== 'carousel_pics').forEach((key) => formData.append(key, payload[key]));
    payload.carouselPics.forEach((pic) => formData.append('carouselPics[]', pic));
    const result = await this.callApi(
      `${this.baseUrl}/${this.resourceName}${id ? `/${id}` : ''}`,
      'POST',
      formData,
      this.createHeaders(null),
    );
    return result;
  }

  async deletePic(picId) {
    const result = await this.callApi(
      `${this.baseUrl}/carousel-pics/${picId}`,
      'DELETE',
      this.createHeaders(),
    );
    return result;
  }

  async list(placeId) {
    const result = await this.callApi(`${this.baseUrl}/${this.resourceName}/list/${placeId}`);
    return result;
  }
}
