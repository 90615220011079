export const AppActionTypes = {
  INIT: 'APP/INIT',
  GET_ENUM_ORDER_STATUSES: 'APP/GET_ENUM_ORDER_STATUSES',
  SET_ENUM_ORDER_STATUSES: 'APP/sET_ENUM_ORDER_STATUSES',
  GET_LOGIN_STATUS: 'APP/GET_LOGIN_STATUS',
  SET_LOGIN_STATUS: 'APP/SET_LOGIN_STATUS',
  GET_LOGIN_DATA: 'APP/GET_LOGIN_DATA',
  SET_LOGIN_DATA: 'APP/SET_LOGIN_DATA',
  SET_LOGIN_DATA_DB: 'APP/SET_LOGIN_DATA_DB',
  DO_SYNC_DATA: 'APP/DO_SYNC_DATA',
  SET_IS_APP_SETUP: 'APP/SET_IS_APP_SETUP',
  SET_APP_STATE: 'APP/SET_APP_STATE',
  CHECK_HAS_ACCEPTED_RULES: 'UI/CHECK_HAS_ACCEPTED_RULES',
  HAS_ACCEPTED_RULES: 'UI/HAS_ACCEPTED_RULES',
  ADD_TRACE_NAVIGATION: 'UI/ADD_TRACE_NAVIGATION',
  SAVE_APP_STATE: 'APP/SAVE_APP_STATE',

  ADMIN_GET_BOOKINGS: 'APP/ADMIN_GET_BOOKINGS',

  DO_LOGIN: 'APP/DO_LOGIN',
  DO_SIGNUP: 'APP/DO_SIGNUP',
  DO_LOGOUT: 'APP/DO_LOGOUT',
};
