import { ApiFactory } from '../factories/api.factory';

export class PlacesApi extends ApiFactory {
  static validate(payload) {
    if (!payload.carouselPics) {
      throw new Error("Il comune necessita di almeno un'immagine");
    }
  }

  async createOrUpdate(payload, id = null) {
    this.validate(payload);
    const formData = new FormData();
    Object.key(payload).filter((e) => e !== 'carouselPics').forEach((key) => formData.append(key, payload[key]));
    payload.carouselPics.forEach((pic) => formData.append('carouselPics[]', pic));
    const result = await this.callApi(
      `${this.baseUrl}/${this.resourceName}/${id ? '' : id}`,
      'POST',
      formData,
      this.createHeaders('multipart/form-data'),
    );
    return result;
  }
}
