import {
  call, put, select, takeEvery
} from 'redux-saga/effects';
import { SagasFactory } from '../factories/sagas.factory';
import {
  addLoadingTask, setDialogData, setDialogOpened, solveLoadingTask
} from '../ui/uiActions';
import { PitchesActions } from './pitches.actions';
import pitchesActionTypes from './pitches.actionTypes';
import { pitchesSelectors } from './pitches.selectors';
import { PitchesApi } from './pitches.api';
import { UiComponetKeys } from '../ui/uiInitialState';

class PitchesSagasFactory extends SagasFactory {
  * list({ payload: { placeId } }) {
    try {
      yield put(addLoadingTask());
      yield this.checkLogin();

      const list = yield call(this.apiClient.list.bind(this.apiClient), placeId);
      yield put(this.actions.setList(list));
    } catch (e) {
      console.error(e);
      yield put(setDialogData(UiComponetKeys.ERROR_DIALOG, { msg: `Impossibile recuperare la lista di ${this.resourceName} (${e.message})` }));
      yield put(setDialogOpened(UiComponetKeys.ERROR_DIALOG, true));
    } finally {
      yield put(solveLoadingTask());
    }
  }

  // eslint-disable-next-line class-methods-use-this, require-yield
  * deletePic({ payload: { pitchId, picId } }) {
    try {
      yield put(addLoadingTask());
      yield this.checkLogin();

      yield call(this.apiClient.deletePic.bind(this.apiClient), picId);
      const pitch = yield select(pitchesSelectors.byId()(pitchId));
      pitch.carousel_pics = pitch.carousel_pics.filter((pic) => pic.carouselPicId !== picId);
      yield put(this.actions.setById({ payload: pitch, id: pitchId }));
    } catch (e) {
      console.error(e);
      yield put(setDialogData(UiComponetKeys.ERROR_DIALOG, { msg: `Impossibile recuperare la lista di ${this.resourceName} (${e.message})` }));
      yield put(setDialogOpened(UiComponetKeys.ERROR_DIALOG, true));
    } finally {
      yield put(solveLoadingTask());
    }
  }

  * watchDeletePic() {
    yield takeEvery(this.actionTypes.DELETE_CAROUSEL_PIC, this.deletePic.bind(this));
  }

  sliceSagas() {
    return [
      this.watchDeletePic.bind(this),
    ];
  }
}

const factory = new PitchesSagasFactory('pitches', 'pitches', PitchesApi, PitchesActions, pitchesActionTypes);
export const PitchesSagas = factory.getSagas();
