import { ActionsFactory } from '../factories/actions.factory';
import pitchesActionTypes from './pitches.actionTypes';

class PitchesActionsFactory extends ActionsFactory {
  getList(placeId = 0) {
    return {
      type: this.actionsTypes.GET_LIST,
      payload: { placeId }
    };
  }

  deletePic(pitchId, picId) {
    return {
      type: this.actionsTypes.DELETE_CAROUSEL_PIC,
      payload: { pitchId, picId }
    };
  }
}

export const PitchesActions = new PitchesActionsFactory('pitches', pitchesActionTypes);
