import flags from './app/static/lang/flags.json';

export const WEB_BASE_URL = 'https://carpfishing.h501.io/api';

export const APP_LANGS = {
  ITA: {
    key: 'ITA',
    description: 'Italiano',
    icon: flags.ITA,
    calendarI18N: {
      i18n: {
        dayNames: {
          short: ['Dom', 'Lun', 'Mar', 'Mer', 'Gio', 'Ven', 'Sab'],
          long: ['Domenica', 'Lunedì', 'Martedì', 'Mercoledì', 'Giovedì', 'Venerdì', 'Sabato'],
        },
        monthNames: {
          short: ['Gen', 'Feb', 'Mar', 'Apr', 'Mag', 'Giu', 'Lug', 'Ago', 'Set', 'Ott', 'Nov', 'Dic'],
          long: [
            'Gennaio',
            'Febbraio',
            'Marzo',
            'Aprile',
            'Maggio',
            'Giugno',
            'Luglio',
            'Agosto',
            'Settembre',
            'Ottobre',
            'Novembre',
            'Dicembre',
          ],
        },
      },
      startDayOfWeek: 1,
      langKey: 'it',
    },
  },
  ENG: {
    key: 'ENG',
    description: 'English',
    icon: flags.ENG,
    calendarI18N: {
      startDayOfWeek: 1,
      langKey: 'en',
    },
  },
};

export const i18n = {
  ITA: {
    'actions.ok': 'OK',
    'actions.cancel': 'ANNULLA',
    'actions.close': 'CHIUDI',
    'actions.go_back': 'INDIETRO',
    'actions.choose': 'SCEGLI',
    'actions.signup': 'REGISTRATI',
    'actions.update': 'AGGIORNA',
    'actions.find': 'CERCA',
    'actions.add': 'AGGIUNGI',
    'actions.delete': 'CANCELLA',
    'actions.update_account': 'AGGIORNA DATI',
    'actions.login': 'ACCEDI',
    'actions.logout': 'ESCI',
    'actions.view_now': 'VISUALIZZA',
    'actions.book_now': 'PRENOTA SUBITO',
    'actions.pay_now': 'PAGA ORA',
    'actions.book': 'PRENOTA',
    'actions.update_booking': 'AGGIORNA PRENOTAZIONE',
    'action.book_all_day': 'PRENOTA TUTTO IL GIORNO',
    'actions.choose_booking_date': 'Scegli una data per la prenotazione.',

    'warnings.general': 'Attenzione.',
    'warnings.no_login_detected_long': 'Sembra che non hai eseguito il login.',
    'warnings.no_login_detected_short': 'Non sei loggato.',
    'warnings.login_before_booking': 'Per poter eseguire una prenotazione dovrai registrarti e poi eseguire il login. Se sei già registrato dovrai continuare eseguendo il login.',

    'errors.unknown': 'Errore sconosciuto',
    'errors.rest.get.places': 'Impossibile recuperare i comuni e le piazzole dal server.',
    'errors.rest.get.bookings': 'Impossibile recuperare le prenotazioni dal server.',
    'errors.rest.get.account': "Impossibile recuperare i dettagli dell'account.",
    'errors.rest.signup.email_already_used': 'Email già registrata.',
    'errors.account.invalid_email': "L'email fornita non è valida.",
    'errors.account.invalid_password': 'La password deve essere composta da almeno 8 caratteri e deve contenere almeno un carattere minuscuolo e uno maiuscolo, un numero e un carattere speciale.',
    'errors.account.invalid_name': 'Fornire un nome della persona fisica.',
    'errors.account.invalid_last_name': 'Fornire un cognome della persona fisica.',
    'errors.account.invalid_fiscal_code': 'Codice fiscale fornito non corretto.',
    'errors.account.invalid_fishing_license_num': 'Licenza di pesca non valida.',
    'errors.account.invalid_terms_check': 'Per proseguire bisogna aver letto, compreso e accettato le condizioni sulla privacy.',
    'errors.account.invalid_telephone_prefix': 'Prefisso telefonico non valido (deve includere il +)',
    'errors.account.invalid_telephone': 'Numero di telefono non valido',
    'errors.login_expired': 'Sessione scaduta.',

    'ui.loading': 'Caricamento',

    'ui.main_drawer.pitches': 'Piazzole',
    'ui.main_drawer.places': 'Comuni',
    'ui.main_drawer.signup': 'Registrati',
    'ui.main_drawer.login': 'Accedi',
    'ui.main_drawer.account': 'Account',
    'ui.main_drawer.bookings': 'Prenotazioni',
    'ui.main_drawer.timeframes': 'Fasce orarie',
    'ui.main_drawer.users': 'Utenti',
    'ui.main_drawer.logout': 'Esci',

    'ui.pitch_label': 'Piazzola',

    'ui.account.name': 'Nome',
    'ui.account.last_name': 'Cognome',
    'ui.account.email': 'Email',
    'ui.account.password': 'Password',
    'ui.account.fiscal_code': 'Codice fiscale',
    'ui.account.fishing_license_num': 'Numero licenza di pesca',
    'ui.account.terms_check': ' Ho letto e accetto le norme sulla privcacy e i termini di utilizzo.',
    'ui.account.telephone_prefix': 'Prefissio telefonico',
    'ui.account.telephone': 'Numero di telefono',

    'ui.reles_dialog.title': 'Regolamento di pesca sul posto',
    'ui.rules_dialog.actions.accept_rules': 'Ho letto attentamente e compreso il regolamento.',

    'ui.bookings.place': 'Comune',
    'ui.bookings.booked_pitch': 'Prenotazione Piazzola n°',
    'ui.bookings.booking_day': 'del',
    'ui.boookings.full_day': 'Giornata intera',

    'pitch.pitch_name': 'Nome della piazzola',
    'pitch.pitch_description': 'Descrizione della piazzola',
    'pitch.pitch_pics': 'Immagini della piazzola',
    'pitch.pitch_latitude': 'Latitudine',
    'pitch.pitch_longitude': 'Longitudine',
    'pitch.pitch_price': 'Prezzo di prenotazione',
  },
  ENG: {
    'actions.ok': 'OK',
    'actions.cancel': 'CANCEL',
    'actions.close': 'CLOSE',
    'actions.go_back': 'BACK',
    'actions.choose': 'CHOOSE',
    'actions.signup': 'SIGNUP',
    'actions.update_account': 'UPDATE DATA',
    'actions.login': 'LOGIN',
    'actions.logout': 'LOGOUT',
    'actions.view_now': 'VIEW NOW',
    'actions.book_now': 'RESERVE NOW',
    'actions.pay_now': 'PAY NOW',
    'actions.book': 'RESERVE',
    'actions.update_booking': 'UPDATE RESERVATION',
    'action.book_all_day': 'RESERVE ALL DAY',
    'actions.choose_booking_date': 'Choose a reservation day',

    'warnings.general': 'Warning.',
    'warnings.no_login_detected_long': 'You are not signed in.',
    'warnings.no_login_detected_short': 'You are not signed in.',
    'warnings.login_before_booking': 'To create a reservation you need to signup. If you are already signed up you just need to login.',

    'errors.unknown': 'Unknown error.',
    'errors.rest.get.places': "Can't retrieve places and pitches data from remote.",
    'errors.rest.get.bookings': "Can't retrieve reservations data from remote.",
    'errors.rest.get.account': "Can't retrieve account data from remote.",
    'errors.rest.signup.email_already_used': 'Email already registered.',
    'errors.account.invalid_email': 'Invalid email.',
    'errors.account.invalid_password': 'Password must be minimum 8 characters long, must contain an upper case and a lower case character, must include a number and a special character.',
    'errors.account.invalid_name': 'Name is needed.',
    'errors.account.invalid_last_name': 'Last name is needed.',
    'errors.account.invalid_fiscal_code': 'Invalid fiscal code (italian format)',
    'errors.account.invalid_fishing_license_num': 'Invalid fishing license.',
    'errors.account.invalid_terms_check': 'You need to read and accept the usage terms to continue.',
    'errors.account.invalid_telephone_prefix': 'Invalid telephone prefix (must include "+").',
    'errors.account.invalid_telephone': 'Invalid telephone.',

    'ui.loading': 'Loading',

    'ui.main_drawer.home': 'Pitches',
    'ui.main_drawer.signup': 'Signup',
    'ui.main_drawer.login': 'Login',
    'ui.main_drawer.account': 'Account',
    'ui.main_drawer.bookings': 'Reservations',
    'ui.main_drawer.users': 'Utenti',

    'ui.pitch_label': 'Pitch',

    'ui.account.name': 'Name',
    'ui.account.last_name': 'Last name',
    'ui.account.email': 'Email',
    'ui.account.password': 'Password',
    'ui.account.fiscal_code': 'Fiscal code',
    'ui.account.fishing_license_num': 'Fishing license number',
    'ui.account.terms_check': 'I accept the terms of use and the privacy policy.',
    'ui.account.telephone_prefix': 'Telephone prefix',
    'ui.account.telephone': 'Telephone number',

    'ui.reles_dialog.title': 'Fishing rules',
    'ui.rules_dialog.actions.accept_rules': 'I have read and understood the rules.',

    'ui.bookings.place': 'Place',
    'ui.bookings.booked_pitch': 'Reservation for pitch n°',
    'ui.bookings.booking_day': 'reservation day',
    'ui.boookings.full_day': 'Full day',
  },
};

export const DEFAULT_IMG_URL = 'https://carpfishing.h501.io/img/no-image.png';
