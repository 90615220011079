import { WEB_BASE_URL } from '../../../constants';

export class ApiFactory {
  resourceName;

  baseUrl;

  jwt;

  needsAuth = true;

  constructor(resourceName) {
    if (!resourceName) {
      throw new Error('No resource name given');
    }
    this.resourceName = resourceName;
    this.baseUrl = WEB_BASE_URL;
  }

  setJwt(jwt) {
    this.jwt = jwt;
  }

  // eslint-disable-next-line no-unused-vars
  createHeaders(contentType = 'application/json', acceptContentType = 'application/json') {
    if (this.needsAuth && !this.jwt) {
      throw new Error('No JWT provided');
    }
    const headers = new Headers();
    if (contentType) {
      headers.append('Content-Type', contentType);
    }
    headers.append('Accept', acceptContentType);
    headers.append('Authorization', `Bearer ${this.jwt}`);
    return headers;
  }

  async callApi(url, method = 'GET', body = undefined, headers = this.createHeaders()) {
    const request = await fetch(url, {
      headers,
      method,
      body,
    });
    if (!request.ok) {
      throw new Error(`Http call for ${this.resourceName} (${url}) failed with code ${request.status}`);
    }
    const response = await request.json();
    return response;
  }

  /**
     * @returns {Promise<Array>}
     */
  async list() {
    const result = await this.callApi(`${this.baseUrl}/${this.resourceName}/list`);
    return result;
  }

  /**
     *
     * @param {number} id
     * @returns {Promise<object>} instance
     */
  async byId(id) {
    const result = await this.callApi(`${this.baseUrl}/${this.resourceName}/${id}`);
    return result;
  }

  /**
     * @param {object} payload
     * @returns {object} updated
     */
  async createOrUpdate(payload, id) {
    const result = await this.callApi(`${this.baseUrl}/${this.resourceName}${id ? `/${id}` : ''}`, 'POST', JSON.stringify(payload));
    return result;
  }
}
