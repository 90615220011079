import { WEB_BASE_URL } from '../../../constants';
import { getI18NText } from '../../utils/utils';

export const login = async (userEmail, userPassword) => {
  const response = await fetch(`${WEB_BASE_URL}/auth/login`, {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({ userEmail, userPassword }),
  });
  const responseData = await response.json();
  if (!response.ok) {
    throw new Error(responseData.responseMessage);
  }
  return responseData;
};

export const refreshToken = async (jwt) => {
  const response = await fetch(`${WEB_BASE_URL}/auth/refresh`, {
    method: 'GET',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: `Bearer ${jwt}`
    },
  });
  const responseData = await response.json();
  if (!response.ok) {
    throw new Error(responseData.responseMessage);
  }
  return responseData;
};

export const signup = async (payload, curLang) => {
  const response = await fetch(`${WEB_BASE_URL}/users/new`, {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(payload),
  });
  const responseData = await response.json();
  if (responseData.emailExists) {
    throw new Error(getI18NText('errors.rest.signup.email_already_used', curLang));
  }
  if (!response.ok) {
    throw new Error(responseData.responseMessage);
  }
  return true;
};

export const addTracingRecord = async (payload) => {
  console.log('Tracing', payload);
  return true;
};
