/* eslint-disable prefer-regex-literals */
import { i18n } from '../../constants';

export async function checkConnection() {
  const response = await fetch('https://google.com');
  return response.ok;
}

// export const isValidEmail = email => email.match(/^(a-zA-Z0-9).+\@(a-zA-Z0-9).+\.(a-z).+/);
export const isValidEmail = (email) => /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(email);

export const isValidPassword = (password) => {
  const numberRegex = new RegExp('[0-9]+');
  const upperCharRegex = new RegExp('[a-z]+');
  const lowerCharRegex = new RegExp('[A-Z]+');
  const specialCharRegex = new RegExp('[\\W]+');
  return password.length > 8
    && numberRegex.test(password)
    && upperCharRegex.test(password)
    && lowerCharRegex.test(password)
    && specialCharRegex.test(password);
};

export const isValidFiscalCode = (fiscalCode) => /^[A-Z]{6}\d{2}[A-Z]\d{2}[A-Z]\d{3}[A-Z]$/.test(fiscalCode);

export const isValidTelephonePrefix = (prefix) => /^\+\d{1,3}$/.test(prefix);

export const isValidTelephone = (telephone) => /^\d{9,10}$/.test(telephone);

export const getI18NText = (key, lang = 'ITA') => {
  if (!i18n[lang] || !i18n[lang][key]) {
    return 'No valid translation available';
  }
  return i18n[lang][key];
};
