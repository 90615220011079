export const selectEnumOrderStatuses = (state) => state.app.enums.orderStatuses;

// export const selectLoginStatus = (state) => state.app.isLogged;

export const selectLoginData = (state) => state.app.login;

export const selectIsAppSetup = (state) => state.app.isAppSetup;

export const selectHasAcceptedRules = (state) => state.app.hasAcceptedRules;

export const selectAdminBookings = (state) => (
  state.app.login.isAdmin ? state.app.login.bookings : []
);
