import {
  Card, CardContent, CardHeader, CircularProgress, Modal
} from '@mui/material';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { UiComponetKeys } from '../slices/ui/uiInitialState';
import { selectCurrentLanguage, selectIsDialogOpened } from '../slices/ui/uiSelectors';
import { getI18NText } from '../utils/utils';

const modalStyle = {
  display: 'flex',
  justifyContent: 'center'
};

const cardStyle = {
  alignSelf: 'center',
  width: '50%',
};

export function LoadingDialogComponent({ dialogKey }) {
  const isOpened = useSelector(selectIsDialogOpened(dialogKey));
  const curLang = useSelector(selectCurrentLanguage);

  return (
    <Modal sx={modalStyle} open={isOpened}>
      <Card sx={cardStyle} disabled>
        <CardHeader title={getI18NText('ui.loading', curLang)} />
        <CardContent sx={modalStyle}>
          <div style={{ alignSelf: 'center' }}>
            <CircularProgress color="inherit" />
          </div>
        </CardContent>
      </Card>
    </Modal>
  );
}

LoadingDialogComponent.propTypes = {
  dialogKey: PropTypes.string,
};

LoadingDialogComponent.defaultProps = {
  dialogKey: UiComponetKeys.LOADING_DIALOG
};
