export const appInitialState = {
  hasAcceptedRules: false,
  lang: 'ITA',
  admin: {
    bookings: [],
  },
  login: {
    jwt: null,
  },
};
