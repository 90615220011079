import { ActionsFactory } from '../factories/actions.factory';
import bookingsActionTypes from './bookings.actionTypes';

export class BookingsActionsFactory extends ActionsFactory {
  getLockedBookings() {
    return {
      type: this.actionsTypes.GET_LOCKED_BOOKINGS,
    };
  }

  createLockedBooking(payload) {
    return {
      type: this.actionsTypes.CREATE_LOCKED_BOOKING,
      payload
    };
  }

  deleteLockedBooking(id) {
    return {
      type: this.actionsTypes.DELETE_LOCKED_BOOKING,
      payload: { id }
    };
  }

  setLockedBookings(list) {
    return {
      type: this.actionsTypes.SET_LOCKED_BOOKINGS,
      payload: list
    };
  }
}

export const BookingsActions = new BookingsActionsFactory('bookings', bookingsActionTypes);
