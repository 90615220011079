import { AppActionTypes } from './appActionTypes';

export const initApp = () => ({
  type: AppActionTypes.INIT
});

export const setAppEnumOrderStatuses = (statuses) => ({
  type: AppActionTypes.SET_ENUM_ORDER_STATUSES,
  payload: statuses,
});

export const setLoginStatus = (isLogged) => ({
  type: AppActionTypes.SET_LOGIN_STATUS,
  payload: isLogged,
});

export const setLoginData = (lastTime, token, userData) => ({
  type: AppActionTypes.SET_LOGIN_DATA,
  payload: {
    lastTime,
    token,
    userData,
  },
});

export const setLoginDataDb = (lastTime, token) => ({
  type: AppActionTypes.SET_LOGIN_DATA_DB,
  payload: {
    lastTime,
    token,
  },
});

export const setIsAppSetup = (isSetup) => ({
  type: AppActionTypes.SET_IS_APP_SETUP,
  payload: isSetup,
});

export const setAppState = (state) => ({
  type: AppActionTypes.SET_APP_STATE,
  payload: state,
});

export const checkHasAcceptedRules = () => ({
  type: AppActionTypes.CHECK_HAS_ACCEPTED_RULES,
});

export const hasAcceptedRules = () => ({
  type: AppActionTypes.HAS_ACCEPTED_RULES,
});

export const addTracingRecord = (payload) => ({
  type: AppActionTypes.ADD_TRACE_NAVIGATION,
  payload,
});

export const doLogin = (email = '', password = '') => ({
  type: AppActionTypes.DO_LOGIN,
  payload: { email, password },
});

export const doSignup = (payload) => ({
  type: AppActionTypes.DO_SIGNUP,
  payload,
});

export const doLogout = () => ({
  type: AppActionTypes.DO_LOGOUT,
});

export const getAdminBookings = (forceOnline) => ({
  type: AppActionTypes.ADMIN_GET_BOOKINGS,
  payload: { forceOnline },
});
