import { ApiFactory } from '../factories/api.factory';

export class BookingsApi extends ApiFactory {
  async getLockedBookings() {
    const result = await this.callApi(`${this.baseUrl}/${this.resourceName}/lock/list`, 'GET');
    return result;
  }

  async createLockedBooking(payload) {
    const result = await this.callApi(`${this.baseUrl}/${this.resourceName}/lock`, 'PUT', JSON.stringify(payload));
    return result;
  }

  async deleteLockedBooking(id) {
    const result = await this.callApi(`${this.baseUrl}/${this.resourceName}/lock/${id}`, 'DELETE');
    return result;
  }
}
