import {
  Box, Button, Paper, Table, TableBody, TableCell,
  TableContainer, TableHead, TableRow, TextField, Typography
} from '@mui/material';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { selectCurrentLanguage } from './slices/ui/uiSelectors';
import { UsersActions } from './slices/users/users.actions';
import { usersSelectors } from './slices/users/users.selectors';
import { getI18NText } from './utils/utils';

const INITIAL_FILTERS = {
  email: '',
  name: '',
  latname: '',
  fiscalCode: '',
};

export default function UsersComponent() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const users = useSelector(usersSelectors.list());
  const curLang = useSelector(selectCurrentLanguage);
  const [filteredUsers, setFilteredUsers] = useState(users);
  const [filters, setFilters] = useState(INITIAL_FILTERS);

  useEffect(() => {
    dispatch(UsersActions.getList());
  }, []);

  useEffect(() => {
    setFilteredUsers(users.filter((user) => (
      user.userEmail.includes(filters.email)
      && user.userName.includes(filters.name)
      && user.userLastname.includes(filters.latname)
      && user.userFiscalCode.includes(filters.fiscalCode)
    )));
  }, [filters, users]);

  const onChangeEmail = (e) => setFilters({ ...filters, email: e.target.value });
  const onChangeName = (e) => setFilters({ ...filters, name: e.target.value });
  const onChangeLastname = (e) => setFilters({ ...filters, latname: e.target.value });
  const onChangeFiscalCode = (e) => setFilters({ ...filters, fiscalCode: e.target.value });

  return (
    <Box>
      <Box sx={{
        marginY: '2rem'
      }}
      >
        <Typography sx={{ padding: 1 }}>Filtra per</Typography>
        <Box sx={{
          display: 'flex',
        }}
        >

          <TextField sx={{ padding: 1, alignSelf: 'center' }} label="Email" value={filters.email} onChange={onChangeEmail} />
          <TextField sx={{ padding: 1, alignSelf: 'center' }} label="Nome" value={filters.name} onChange={onChangeName} />
          <TextField sx={{ padding: 1, alignSelf: 'center' }} label="Cognome" value={filters.lastName} onChange={onChangeLastname} />
          <TextField sx={{ padding: 1, alignSelf: 'center' }} label="Codice fiscale" value={filters.fiscalCode} onChange={onChangeFiscalCode} />

        </Box>
      </Box>
      <TableContainer component={Paper} sx={{ margin: 1 }}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Email</TableCell>
              <TableCell align="right">Nome</TableCell>
              <TableCell align="right">Cognome</TableCell>
              <TableCell align="right">Codice fiscale</TableCell>
              <TableCell align="center">Azioni</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {filteredUsers.map((user) => (
              <TableRow
                key={`${user.userEmail}${user.userId}`}
                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
              >
                <TableCell component="th" scope="row">
                  {user.userEmail}
                </TableCell>
                <TableCell align="right">{user.userName}</TableCell>
                <TableCell align="right">{user.userLastname}</TableCell>
                <TableCell align="right">{user.userFiscalCode}</TableCell>
                <TableCell align="center">
                  <Button
                    variant="contained"
                    onClick={() => navigate(`/bookings/list?userEmail=${user.userEmail}`)}
                  >
                    {getI18NText('ui.main_drawer.bookings', curLang)}
                  </Button>
                </TableCell>
              </TableRow>
            ))}
            {
              filteredUsers.length === 0
                ? (
                  <TableRow>
                    <TableCell align="center" colSpan={5}>Non ci sono elementi da mostrare.</TableCell>
                  </TableRow>
                )
                : null
            }
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
}
