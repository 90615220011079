import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import {
  Button, createTheme, TextField, Typography
} from '@mui/material';
import { doLogin } from './slices/app/appActions';
import { selectLoginData } from './slices/app/appSelectors';
import { getI18NText, isValidEmail } from './utils/utils';
import { selectCurrentLanguage } from './slices/ui/uiSelectors';

const theme = createTheme();

export default function LoginComponent() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const loginData = useSelector(selectLoginData);
  const [formData, setFormData] = useState({
    email: '',
    password: '',
  });
  const [formError, setFormError] = useState(null);
  const curLang = useSelector(selectCurrentLanguage);

  useEffect(() => {
    if (loginData.jwt && loginData.jwt !== '') {
      navigate('/places');
    }
  }, [loginData]);

  const onLogin = () => {
    if (!isValidEmail(formData.email)) {
      setFormError(getI18NText('errors.account.invalid_email', curLang));
      return;
    }
    dispatch(doLogin(formData.email, formData.password));
    setFormError(null);
  };

  return (
    <div style={
      {
        display: 'flex',
        flexDirection: 'column',
        height: '80vh',
        width: '80vw',
      }
    }
    >
      <TextField style={{ alignSelf: 'center', width: '50%', marginTop: theme.spacing(4) }} label="Email" value={formData.email} onChange={(e) => setFormData({ ...formData, email: e.target.value.toLowerCase() })} />
      <TextField style={{ alignSelf: 'center', width: '50%', marginTop: theme.spacing(4) }} label="Password" type="password" autoComplete="current-password" value={formData.password} onChange={(e) => setFormData({ ...formData, password: e.target.value })} />
      <Button style={{ alignSelf: 'center', marginTop: theme.spacing(4) }} onClick={onLogin} variant="contained">{getI18NText('actions.login', curLang)}</Button>
      <Typography style={{ alignSelf: 'center', width: '50%', marginTop: theme.spacing(4) }} status="danger">{formError}</Typography>
    </div>
  );
}
