import { InitialStateFactory } from './initialState.factory';

// eslint-disable-next-line max-len
export function ReducerFactory(sliceName, initialState = InitialStateFactory(), customActions = {}) {
  // eslint-disable-next-line no-param-reassign
  sliceName = sliceName.toUpperCase();
  const actions = {
    [`${sliceName}/SET`]: (state, payload) => ({ ...state, ...payload }),
    [`${sliceName}/REPLACE`]: (_state, payload) => payload,
    [`${sliceName}/RESET`]: () => initialState,
    [`${sliceName}/CLEAR`]: () => ({}),
    [`${sliceName}/ADD`]: (state, payload) => ({ ...state, list: [...state.list, payload] }),
    [`${sliceName}/SET_LIST`]: (state, list) => ({ ...state, list }),
    [`${sliceName}/SET_BY_ID`]: (state, { payload, id }) => (
      {
        ...state,
        list: state.list.map((e) => (e.id === id ? ({ ...e, ...payload }) : e)),
        current: state.current?.id === id ? ({ ...state.current, ...payload }) : state.current,
      }),
    ...customActions,
  };
  // eslint-disable-next-line default-param-last
  return function reducer(state = initialState, { payload, type }) {
    try {
      return actions[type](state, payload);
    } catch (e) {
      return state;
    }
  };
}
