import { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import {
  Box,
  Button, createTheme, Grid,
  ImageList, ImageListItem, TextField, Typography
} from '@mui/material';
import PlusIcon from '@mui/icons-material/AddToPhotos';
import DeleteIcon from '@mui/icons-material/Delete';
import UpdateIcon from '@mui/icons-material/Update';

import { selectCurrentLanguage } from './slices/ui/uiSelectors';
import { getI18NText } from './utils/utils';
import { pitchesSelectors } from './slices/pitches/pitches.selectors';
import HoverComponent from './ui/utils/HoverComponent';
import { setDialogData, setDialogOpened } from './slices/ui/uiActions';
import { UiComponetKeys } from './slices/ui/uiInitialState';
import * as promisify from './utils/promisify';
import { DEFAULT_IMG_URL } from '../constants';
import { PitchesActions } from './slices/pitches/pitches.actions';
import { ActionDialogComponent, ACTION_DIALOG_EVENTS } from './ui/ActionDialogComponent';

const theme = createTheme();

const initialFormValue = {
  pitchName: '',
  pitchDescription: '',
  pitchHelpUrl: '',
  pitchLatitude: '',
  pitchLongitude: '',
  pitchTelephone: '',
  pitchTermsConditions: '',
  pitch_placeId: '',
  carouselPics: []
};

const CONFIRM_DELETE_PITCH_DIALOG_KEY = 'CONFIRM_DELETE_PITCH_DIALOG_KEY';

export default function PitchComponent() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const pitchById = pitchesSelectors.byId();
  const pitchList = pitchesSelectors.list();

  const { pitchId, placeId } = useParams();
  const pitch = useSelector(pitchById(parseInt(pitchId, 10)));
  const pitches = useSelector(pitchList);
  const [formValue, setFormValue] = useState(
    pitch
      ? {
        ...initialFormValue,
        ...pitch,
      }
      : {
        ...initialFormValue
      }
  );
  const [isUploadingCarousel, setIsUploadingCarousel] = useState(false);
  const [tempPreviewImg, setTempPreviewImg] = useState(pitch?.pitchImgUrl || DEFAULT_IMG_URL);
  const carouselInputRef = useRef();
  const pitchImgRef = useRef();
  const curLang = useSelector(selectCurrentLanguage);

  useEffect(() => {
    if (pitchId && !pitch) {
      dispatch(PitchesActions.getList());
    }
    if (pitch) {
      setTempPreviewImg(pitch.pitchImgUrl);
      setFormValue({
        ...initialFormValue,
        ...pitch,
      });
    }
  }, [pitchId, pitch]);

  useEffect(() => {
    if (pitch?.pitch_placeId === null) {
      navigate(-1);
    }
    if (pitch?.updated && isUploadingCarousel) {
      setFormValue({
        ...formValue,
        carouselPics: []
      });
      setIsUploadingCarousel(false);
    }
  }, [pitch]);

  useEffect(() => {
    const added = pitches.find((e) => e.added === true);
    if (added) {
      navigate(`/places/${placeId}/pitches/${added.pitchId}`);
    }
  }, [pitches]);

  const onSubmitPitch = () => {
    if (!pitchId) { // if is not an update
      dispatch(PitchesActions.create({
        ...formValue,
        pitch_placeId: parseInt(placeId, 10)
      }));
      return;
    }
    setIsUploadingCarousel(true);
    dispatch(PitchesActions.update(formValue, pitch.pitchId));
  };

  const onConfirmDeletePitch = () => {
    dispatch(setDialogData(CONFIRM_DELETE_PITCH_DIALOG_KEY, {
      msg: 'La piazzola verrà cancellata, continuare?',
      acceptButton: true,
      closeButtonText: getI18NText('actions.cancel', curLang),
    }));
    dispatch(setDialogOpened(CONFIRM_DELETE_PITCH_DIALOG_KEY, true));
  };

  const onDeletePitch = (event) => {
    if (event === ACTION_DIALOG_EVENTS.ACCEPT_EVENT) {
      dispatch(PitchesActions.update({
        ...formValue,
        pitchIsDeleted: true
      }, pitch.pitchId));
    }
  };

  const onAddCarouselImages = async (e) => {
    if (e.target.files.length === 0) {
      return;
    }
    dispatch(setDialogOpened(UiComponetKeys.LOADING_DIALOG, true));
    const files = Array.from(e.target.files);
    const readPics = await Promise.all(
      files
        .map((file) => promisify.readFileAsUrl(file))
    );
    setFormValue({
      ...formValue,
      carouselPics: [
        ...formValue.carouselPics,
        ...files.map((file, idx) => {
          // eslint-disable-next-line no-param-reassign
          file.base64 = readPics[idx];
          return file;
        }),
      ]
    });
    dispatch(setDialogOpened(UiComponetKeys.LOADING_DIALOG, false));
  };

  const onChangePitchImg = async (e) => {
    if (e.target.files.length === 0) {
      return;
    }
    const preview = await promisify.readFileAsUrl(e.target.files[0]);
    setTempPreviewImg(preview);
    setFormValue({
      ...formValue,
      pitchImg: e.target.files[0]
    });
  };

  const onDeleteNotUploadedPic = (encodedFile) => setFormValue({
    ...formValue,
    carouselPics: formValue.carouselPics.filter((e) => e !== encodedFile)
  });

  const onDeleteUploadedPic = (carouselPicId) => {
    dispatch(PitchesActions.deletePic(pitch.pitchId, carouselPicId));
  };

  return (
    <Grid
      container
      sx={{
        marginTop: theme.spacing(5)
      }}
    >
      <ActionDialogComponent dialogKey={CONFIRM_DELETE_PITCH_DIALOG_KEY} callback={onDeletePitch} />

      <input type="file" ref={carouselInputRef} onChange={onAddCarouselImages} accept="image/jpeg,imge/jpg,image/png" multiple style={{ display: 'none' }} />
      <input type="file" ref={pitchImgRef} onChange={onChangePitchImg} accept="image/jpeg;imge/jpg;image/png" style={{ display: 'none' }} />

      <Grid
        item
        xs={5}
        sx={{
          border: '.1rem solid #f0f0f0',
          padding: '.5rem',
          marginBottom: '2rem'
        }}
      >
        <Box className="flip-card">

          <Typography>Immagine di copertina</Typography>

          <HoverComponent
            front={(
              <img
                width="100%"
                alt="immagine anteprima della pitch"
                src={tempPreviewImg}
              />
            )}
            back={(
              <Box sx={{
                display: 'flex',
                width: '100%',
                height: '100%',
                justifyContent: 'center',
                alignItems: 'center'
              }}
              >
                <Button
                  onClick={() => pitchImgRef.current?.click()}
                  variant="contained"
                  color="info"
                  startIcon={<UpdateIcon />}
                >
                  {getI18NText('actions.update', curLang)}
                </Button>
              </Box>
            )}
          />

        </Box>

      </Grid>

      {/* spacing */}
      <Grid item xs={1} />

      <Grid
        item
        xs={6}
        sx={{
          border: '.1rem solid #f0f0f0',
          padding: '.5rem',
          marginBottom: '2rem',
        }}
      >
        <Typography>Carosello della piazzola</Typography>

        <ImageList
          cols={3}
        >
          <ImageListItem
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center'
            }}
            onClick={() => carouselInputRef.current?.click()}
          >
            <PlusIcon
              sx={{
                width: '4rem',
                height: '4rem',
                padding: '1rem',
                border: '.1rem solid #f0f0f0',
                borderRadius: '50%',
                background: '#f0f0f0',
                cursor: 'pointer'
              }}
            />
          </ImageListItem>
          {
            pitch?.carousel_pics?.map((pic, idx) => (
              <ImageListItem key={pic.carouselPicId}>
                <HoverComponent
                  front={(
                    <img
                      width="100%"
                      alt={`immagine n° ${idx} della pitch`}
                      src={pic.carouselPicImgUrl}
                    />
                  )}
                  back={(
                    <Box sx={{
                      display: 'flex',
                      width: '100%',
                      height: '100%',
                      justifyContent: 'center',
                      alignItems: 'center'
                    }}
                    >
                      <Button
                        onClick={() => onDeleteUploadedPic(pic.carouselPicId)}
                        variant="contained"
                        color="error"
                        startIcon={<DeleteIcon />}
                      >
                        {getI18NText('actions.delete', curLang)}
                      </Button>
                    </Box>
                  )}
                />
              </ImageListItem>
            ))
          }
          {
            formValue.carouselPics?.map((file, idx) => (
              <ImageListItem key={`${file.name}`}>
                <HoverComponent
                  front={(
                    <img
                      width="100%"
                      alt={`immagine n° ${idx} della pitch`}
                      src={file.base64}
                    />
                  )}
                  back={(
                    <Box sx={{
                      display: 'flex',
                      width: '100%',
                      height: '100%',
                      justifyContent: 'center',
                      alignItems: 'center'
                    }}
                    >
                      <Button
                        onClick={() => onDeleteNotUploadedPic(file)}
                        variant="contained"
                        color="error"
                        startIcon={<DeleteIcon />}
                      >
                        {getI18NText('actions.delete', curLang)}
                      </Button>
                    </Box>
                  )}
                />
              </ImageListItem>
            ))
          }
        </ImageList>

      </Grid>

      <Grid
        item
        xs={12}
      >
        <TextField
          sx={{ width: '100%' }}
          label={getI18NText('pitch.pitch_name', curLang)}
          value={formValue.pitchName}
          onChange={(e) => setFormValue({ ...formValue, pitchName: e.target.value })}
        />
      </Grid>

      <Grid
        item
        xs={6}
        sx={{ paddingRight: '.5rem' }}
      >
        <TextField
          sx={{ width: '100%', marginTop: '1rem' }}
          multiline
          minRows={8}
          label={getI18NText('pitch.pitch_description', curLang)}
          value={formValue.pitchDescription}
          onChange={(e) => setFormValue({ ...formValue, pitchDescription: e.target.value })}
        />
      </Grid>

      <Grid
        item
        xs={6}
        sx={{ paddingLeft: '.5rem' }}
      >
        <TextField
          sx={{ width: '100%', marginTop: '1rem' }}
          label={getI18NText('pitch.pitch_latitude', curLang)}
          value={formValue.pitchLatitude}
          onChange={(e) => setFormValue({ ...formValue, pitchLatitude: e.target.value })}
        />

        <TextField
          sx={{ width: '100%', marginTop: '1rem' }}
          label={getI18NText('pitch.pitch_longitude', curLang)}
          value={formValue.pitchLongitude}
          onChange={(e) => setFormValue({ ...formValue, pitchLongitude: e.target.value })}
        />

      </Grid>

      <Grid
        item
        xs={12}
        sx={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'flex-end',
          alignItems: 'center'
        }}
      >
        {
          pitchId
            ? (
              <Button
                onClick={onConfirmDeletePitch}
                variant="contained"
                color="error"
              >
                {getI18NText('actions.delete', curLang)}
              </Button>
            )
            : null
        }

        <Button
          onClick={onSubmitPitch}
          variant="contained"
          sx={{
            marginLeft: '1rem'
          }}
        >
          {pitchId
            ? getI18NText('actions.update', curLang)
            : getI18NText('actions.add', curLang)}
        </Button>
      </Grid>
    </Grid>
  );
}
